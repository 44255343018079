import { ReactComponent as Search } from "../../Images/search.svg";
import Footer from "../Footer";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Liquidated } from "../../Images/doubleTick.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Paginator from "../../shared/Paginator";
import { CurrencyFormatter, formatDate } from "../../../utils/helpers/helper";

function WithdrawlRequest({ data }) {
  const acceptanceStatus = {};
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
      <div className="flex justify-between items-center w-full p-[20px]">
        {/* {search ? ( */}
        <div
          // onClick={() => setSearch(false)}
          className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
        >
          <Search />
          <input
            className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
            placeholder="Search"
          />
        </div>
        {/* ) : ( */}
        <div
          //   onClick={() => {
          //     setSearch(true);
          //   }}
          className="px-3.5 py-2.5 flex md:hidden"
        >
          <Search />
        </div>
        {/* )} */}
        <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
          <Search />
          <input
            className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
            placeholder="Search"
          />
        </div>
      </div>

      <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
        {[
          "S/N",
          "Lender ID",
          "Name",
          "Amount Requested",
          "Date of Request",
          "Status",
          "",
        ]?.map((header) => (
          <div
            key={header}
            className="w-full flex flex-col justify-start items-start"
          >
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">
              {header}
            </div>
            {currentData?.map((item, index) => (
              <div
                key={item?.id}
                className={`h-[72px] w-full px-6 py-4 ${
                  index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"
                } justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}
              >
                {header === "S/N" && startIndex + index + 1}
                {header === "Lender ID" && <div>{item?.user?.userid}</div>}

                {header === "Name" && (
                  <div className=" text-pretty ">
                    {item?.user?.firstName} {item?.user?.lastName}
                  </div>
                )}
                {header === "Date of Request" &&
                  formatDate(new Date(item?.createdDate))}

                {header === "Amount Requested" && (
                  <div>{CurrencyFormatter(item?.amount || 0, "$")}</div>
                )}

                {header === "Loan Amount" && (
                  <div>{CurrencyFormatter(item?.amount || 0, "$")}</div>
                )}

                {header === "Accrued Interest (R & A)" && (
                  <div>{item?.accuredInterest || 0}%</div>
                )}
                {header === "Status" && (
                  <div
                    className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${
                      item?.status === "SUCESS"
                        ? "bg-[#f1fcf2]"
                        : item?.status === "Liquidated"
                        ? " bg-red-100"
                        : "bg-[#ffffea]"
                    }`}
                  >
                    {item?.status === "SUCCESS" ? (
                      <Completed />
                    ) : item?.status === "DECLINED" ? (
                      <Reject />
                    ) : (
                      <Pending />
                    )}
                    <div
                      className={`text-center ${
                        item?.status === "SUCCESS"
                          ? "text-[#1f7634]"
                          : item?.status === "DECLINED"
                          ? " text-red-700"
                          : "text-[#bb6902]"
                      } text-xs font-medium leading-[18px]`}
                    >
                      {/* {`Near Maturity`} */}

                      {item?.status}
                    </div>
                  </div>
                )}

                {header === "" && (
                  <div className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                    <button
                      onClick={() => {
                        navigate(
                          `/approver/lenders-management/lenders-details/${item?.userId}`
                        );
                      }}
                    >
                      View
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <Paginator
        total={data.length || 0}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default WithdrawlRequest;
