import { ReactComponent as Search } from "../../Images/search.svg";
import Footer from "../Footer";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Liquidated } from "../../Images/doubleTick.svg";
import { useState } from "react";
import cancel from "../../Images/Cancel.svg";
import user from "../../Images/userID.png";

function MaturedLoans() {
  const [loanDetails, setLoanDetails] = useState(false);

  const acceptanceStatus = {};
  const basicInfo = [
    {
      id: 1,
      icon: (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.16602 4L5.19861 6.2849C6.68528 7.12726 7.31342 7.12726 8.80009 6.2849L12.8327 4" stroke="#808990" stroke-width="1.5" stroke-linejoin="round" />
          <path
            d="M1.17521 8.3611C1.21335 10.1493 1.23242 11.0434 1.89224 11.7058C2.55206 12.3681 3.47038 12.3912 5.307 12.4373C6.43895 12.4658 7.55975 12.4658 8.6917 12.4373C10.5283 12.3912 11.4466 12.3681 12.1065 11.7058C12.7663 11.0434 12.7854 10.1493 12.8235 8.3611C12.8357 7.78612 12.8357 7.21454 12.8235 6.63956C12.7854 4.85133 12.7663 3.95721 12.1065 3.29488C11.4466 2.63254 10.5283 2.60947 8.6917 2.56332C7.55975 2.53488 6.43895 2.53488 5.307 2.56332C3.47037 2.60946 2.55206 2.63253 1.89224 3.29487C1.23241 3.95721 1.21335 4.85132 1.17521 6.63955C1.16295 7.21453 1.16295 7.78612 1.17521 8.3611Z"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
        </svg>
      ),
      type: "Email Address",
      details: "johndoe@gmail.com",
    },
    {
      id: 2,
      icon: (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2966_17323)">
            <path
              d="M2.45296 7.4667C1.89995 6.50242 1.63293 5.71502 1.47192 4.91687C1.2338 3.73641 1.77874 2.5833 2.68149 1.84752C3.06303 1.53655 3.5004 1.64279 3.72602 2.04756L4.23537 2.96136C4.6391 3.68566 4.84097 4.04781 4.80093 4.43175C4.76089 4.8157 4.48865 5.12841 3.94417 5.75383L2.45296 7.4667ZM2.45296 7.4667C3.57231 9.41849 5.32892 11.1761 7.28297 12.2967M7.28297 12.2967C8.24725 12.8497 9.03465 13.1167 9.83281 13.2778C11.0133 13.5159 12.1664 12.9709 12.9022 12.0682C13.2131 11.6866 13.1069 11.2493 12.7021 11.0237L11.7883 10.5143C11.064 10.1106 10.7019 9.90871 10.3179 9.94875C9.93397 9.98879 9.62126 10.261 8.99585 10.8055L7.28297 12.2967Z"
              stroke="#565C64"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2966_17323">
              <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)" />
            </clipPath>
          </defs>
        </svg>
      ),
      type: "Phone Number",
      details: "081 234 789 1440",
    },
    {
      id: 3,
      icon: (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 8.66699C6.93299 8.66699 8.5 7.09998 8.5 5.16699C8.5 3.23399 6.93299 1.66699 5 1.66699C3.067 1.66699 1.5 3.23399 1.5 5.16699C1.5 7.09998 3.067 8.66699 5 8.66699ZM5 8.66699V13.3337M3.25 11.5837H6.75"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 6.33398C15.933 6.33398 17.5 7.90099 17.5 9.83398C17.5 11.767 15.933 13.334 14 13.334C12.067 13.334 10.5 11.767 10.5 9.83398C10.5 7.90099 12.067 6.33398 14 6.33398ZM14 6.33398V1.66732M12.25 3.41732H15.75"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      type: "Gender",
      details: "Female",
    },
  ];

  return (
    <>
      <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
        <div className="flex justify-between items-center w-full p-[20px]">
          {/* {search ? ( */}
          <div
            // onClick={() => setSearch(false)}
            className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
          >
            <Search />
            <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
          </div>
          {/* ) : ( */}
          <div
            //   onClick={() => {
            //     setSearch(true);
            //   }}
            className="px-3.5 py-2.5 flex md:hidden"
          >
            <Search />
          </div>
          {/* )} */}
          <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
            <Search />
            <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
          </div>
        </div>

        <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Lender ID</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>001</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>002</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>003</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>004</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>005</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>006</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>007</div>
          </div>
          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Name</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>Celine Johnnnes</div>
          </div>
          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Principal Amount</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦1,000,000</div>
          </div>
          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Interest Earned</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦120,000</div>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Total Amount Repaid</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>₦30,000</div>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">Status</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Near Maturity`}</div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">{/* */}</div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-[#f5f6f6] justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
            <div className={`h-[72px] w-full px-6 py-4 bg-white justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
              <button onClick={() => setLoanDetails(true)} className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                {"View"}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loanDetails && (
        <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-center item-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div
              onClick={() => {
                setLoanDetails(false);
              }}
              className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
            >
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <section className=" 100:w-[80%] h-full md:w-full flex justify-center  items-center">
            <div className=" 100:w-full md:w-[550px] h-fit  p-6 bg-white rounded-xl shadow">
              <div className=" w-full items-center flex h-fit flex-col justify-start  gap-6">
                <div className="h-fit  w-full p-4 bg-neutral-100 rounded-lg border border-neutral-300 flex-col justify-center items-center gap-2 inline-flex">
                  <img src={user} className=" w-[60px] h-[60px]" />
                  <div className="w-full text-nowrap flex-col justify-center items-center gap-4 inline-flex">
                    <div className="w-full flex-col justify-center items-center flex">
                      <div className="self-stretch text-center text-neutral-700 text-lg font-medium leading-relaxed">Ijeoma Obi</div>
                      <div className="text-neutral-600 text-sm font-medium leading-tight">obiijeoma32@gmail.com</div>
                    </div>
                    <div className="w-full flex-1 100:flex-wrap   lg:flex-nowrap justify-center items-center gap-6 inline-flex">
                      {basicInfo.map((item) => (
                        <div key={item.id} className="w-fit flex-grow min-w-[130px] justify-center items-center gap-1 inline-flex">
                          {" "}
                          {item.icon} <h4 className="text-gray-500 text-sm font-medium font-['Inter'] leading-tight">{item.details}</h4>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start gap-4 inline-flex overflow-hidden">
                  <div className="self-stretch flex-col justify-start items-start gap-3.5 flex">
                    <h3 className="self-stretch text-neutral-700 text-base font-medium  leading-normal">Loan Details</h3>
                  </div>
                  <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Loan ID</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">#0089890</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Principal Amount</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">₦30,000</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Interest Earned</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">$30,000</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Total Amount Repaid</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">₦30,000</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Disbursement Date</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">17th May 2024 10: 00 PM</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Repayment Date</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">17th May 2025 12: 00 PM</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Repayment Date</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">17th May 2025 12: 00 PM</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Loan Duration</h3>
                      <h3 className="text-neutral-600 text-sm font-semibold leading-tight">12 months</h3>
                    </div>
                    <div className="self-stretch h-px bg-gray-100" />
                    <div className="w-full justify-between items-center inline-flex">
                      <h3 className="text-gray-500 text-xs font-medium leading-none">Loan Status</h3>
                      <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : acceptanceStatus === "Liquidated" ? " bg-gray-50" : "bg-[#ffffea]"}`}>
                        {acceptanceStatus === "ACCEPT" ? <Completed /> : acceptanceStatus === "Liquidated" ? <Liquidated /> : <Pending />}
                        <div className={`text-center ${acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : acceptanceStatus === "Liquidated" ? "text-[#4A4F54]" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{`Matured`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default MaturedLoans;
