import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Search } from "../../Images/search.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import { ReactComponent as Arrow } from "../../Images/outward.svg";
import { useEffect, useState } from "react";
import {
  CurrencyFormatter,
  formatDate,
  formatTime,
} from "../../../utils/helpers/helper";
import Paginator from "../../shared/Paginator";
function Deposit({ data, action, canSearch }) {
  // const [empty, setEmpty] = useState(true);
  const [isData, setData] = useState({});
  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    const filtered = data.filter((item) =>
      [item?.refNo, item?.status, item?.type].some((field) =>
        field.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
        <div className="flex h-fit justify-between items-center w-full lg:p-[16px]">
          {search ? (
            <div
              onClick={() => setSearch(false)}
              className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
            >
              <Search />
              <input
                className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
                placeholder="Search"
              />
            </div>
          ) : (
            <div
              onClick={() => {
                setSearch(true);
              }}
              className="px-3.5 py-2.5 flex md:hidden"
            >
              <Search />
            </div>
          )}

          <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
            <Search />
            <input
              className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
          {" "}
          {["S/N", "Date", "Amount", "Type", "Reference", "Status", "Time"].map(
            (header) => (
              <div
                key={header}
                className="w-full flex flex-col justify-start items-start"
              >
                <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">
                  {header}
                </div>
                {currentData?.map((item, index) => (
                  <div
                    key={item?.id}
                    className={`h-[72px] w-full px-6 py-4 ${
                      index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"
                    } justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}
                  >
                    {header === "S/N" && startIndex + index + 1}
                    {header === "Amount" && (
                      <div className="capitalize">
                        {CurrencyFormatter(item?.amount || 0, "$")}
                      </div>
                    )}
                    {header === "Type" && (
                      <div className="capitalize text-pretty">{item?.type}</div>
                    )}
                    {header === "Date" && (
                      <div>{formatDate(new Date(item?.createdDate))}</div>
                    )}

                    {header === "Reference" && <div>{item?.refNo}</div>}
                    {header === "Progress" && (
                      <div className=" w-full justify-start items-end gap-2 inline-flex">
                        <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                          <div
                            style={{ width: `${item?.progress || 0}%` }}
                            className={`h-full  bg-[#0C4CFF] rounded-[8px] `}
                          ></div>
                        </div>
                        <div className=" w-full flex justify-end items-center">
                          <h5 className="text-[#4a4f54] text-xs font-medium  leading-tight">
                            {item?.progress || 0}%{" "}
                          </h5>
                        </div>
                      </div>
                    )}
                    {header === "Status" && (
                      <div
                        className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${
                          item?.status === "SUCCESS"
                            ? "bg-[#f1fcf2]"
                            : item?.status === "DECLINED"
                            ? "bg-red-100"
                            : "bg-[#ffffea]"
                        }`}
                      >
                        {item?.status === "SUCCESS" ? (
                          <Completed />
                        ) : item?.status === "DECLINED" ? (
                          <Reject />
                        ) : (
                          <Pending />
                        )}
                        <div
                          className={`text-center ${
                            item?.status === "SUCCESS"
                              ? "text-[#1f7634]"
                              : item?.status === "DECLINED"
                              ? "text-red-500"
                              : "text-[#bb6902]"
                          } text-xs font-medium leading-[18px]`}
                        >
                          {item?.status.charAt(0).toUpperCase() +
                            item?.status.slice(1).toLowerCase()}
                        </div>
                      </div>
                    )}

                    {header === "Time" && (
                      <div>{formatTime(new Date(item?.createdDate))}</div>
                    )}
                  </div>
                ))}
              </div>
            )
          )}
        </div>

        <div className="w-full 100:flex lg:hidden flex-col justify-start items-start gap-4 inline-flex">
          {data?.map((item, index) => (
            <>
              <div
                key={index}
                className="w-full  h-fit  justify-between items-center inline-flex"
              >
                <div className="w-fit justify-start items-center gap-3 inline-flex">
                  <div
                    className={`p-2 rounded-full w-10 h-10 justify-center items-center  flex ${
                      item?.type === "DEPOSIT"
                        ? "bg-[#f1fcf2] text-[#1f7634]"
                        : "bg-red-100 text-red-500"
                    }`}
                  >
                    {item?.type === "DEPOSIT" ? (
                      <Arrow className=" rotate-180 w-[18px] h-[18px]" />
                    ) : (
                      <Arrow className=" w-[18px] h-[18px]" />
                    )}
                  </div>
                  <div className=" w-fit flex-col justify-end items-start gap-1.5 inline-flex">
                    <h4 className="text-neutral-700 text-xs font-medium ">
                      {item?.type}
                    </h4>
                    <h2 className="text-gray-500 text-[9px] font-medium ">
                      {formatDate(new Date(item?.createdDate))}
                    </h2>
                  </div>
                </div>
                <div className="w-fit flex-col justify-end items-end gap-1 inline-flex">
                  <h2 className="text-neutral-700 text-sm font-semibold ">
                    {CurrencyFormatter(item?.amount || 0, "$")}
                  </h2>
                  <h5
                    className={`text-center ${
                      item?.status === "SUCCESS"
                        ? "text-[#1f7634]"
                        : item?.status === "DECLINED"
                        ? "text-red-500"
                        : "text-[#bb6902]"
                    } text-center  text-xs font-medium leading-none`}
                  >
                    {item?.status.charAt(0).toUpperCase() +
                      item?.status.slice(1).toLowerCase()}
                  </h5>
                </div>
              </div>
              {index !== data.length - 1 && (
                <div className="h-px w-full bg-gray-200" />
              )}
            </>
          ))}
        </div>

        <Paginator
          total={data.length || 0}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default Deposit;
