function NotificationPreference() {
  return (
    <>
      <div className=" flex-col gap-[32px] w-full flex justify-start items-center">
        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          <h3 className="self-stretch text-[#3a3c3f] text-lg font-medium  leading-7">Notification Preferences</h3>
          <p className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Manage how you receive notifications from Ije.</p>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] 100:hidden lg:flex justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">Notification Preferences</h4>
            {/* <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter current password</p> */}
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="Email Notifications">
                <input required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="checkbox" />
                Email Notifications
              </label>
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="SMS Notifications">
                <input required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="checkbox" />
                SMS Notifications
              </label>
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="App Notifications">
                <input required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="checkbox" />
                App Notifications
              </label>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationPreference;
