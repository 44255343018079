import { CurrencyFormatter, formatDate } from "../../../utils/helpers/helper";

export default function InvestmentCard({ data, action }) {
  return (
    <div className="iphone:min-w-[334px] 100:mini-w-full flex-grow  h-fit 100:p-4 md:p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)]  border border-[#e5e7e8] flex-col justify-start items-center 100:gap-4 md:gap-8 inline-flex ">
      {/* Title */}
      <div className=" w-full justify-between items-start  inline-flex">
        <div className="w-full  flex-col justify-center items-center gap-0.5 inline-flex">
          <h3 className="w-full text-[#242628] text-nowrap 100:text-base md:text-xl font-medium leading-[29px]">
            Loan {data?.id}
          </h3>
          <h5 className="w-full text-[#6c757d] text-xs font-medium leading-[17.40px]">
            {data?.refNo}
          </h5>
        </div>
        <div
          className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${
            data?.status === "ACTIVE"
              ? "bg-[#f1fcf2]"
              : data?.status === "INACTIVE"
              ? "bg-red-100"
              : data?.status === "LIQUIDATED"
              ? "bg-gray-50"
              : "bg-[#ffffea]"
          } `}
        >
          <div
            className={`text-center ${
              data?.status === "ACTIVE"
                ? "text-[#1f7634]"
                : data?.status === "INACTIVE"
                ? "text-red-500"
                : data?.status === "LIQUIDATED"
                ? "text-[#4A4F54]"
                : "text-[#bb6902]"
            }  text-xs font-medium leading-[18px]`}
          >
            {data?.status}
          </div>
        </div>
      </div>
      {/* Details */}
      <div className="w-full 100:hidden md:flex flex-col justify-start items-start gap-3 inline-flex">
        <div className=" w-full justify-between items-start  inline-flex">
          <div className="w-full  flex-col justify-center items-center gap-0.5 inline-flex">
            <h3 className="w-full text-[#4a4f54] text-sm font-medium text-nowrap leading-[29px]">
              {formatDate(new Date(data?.createdDate))}
            </h3>
            <h5 className="w-full text-[#808990] text-xs font-normal leading-[17.40px]">
              Date Lent
            </h5>
          </div>
          <div className="w-full  flex-col justify-end items-end gap-0.5 inline-flex">
            <h3 className="w-fit text-[#4a4f54] text-sm font-medium text-nowrap leading-[29px]">
              {CurrencyFormatter(data?.amount || 0, "$")}
            </h3>
            <h5 className="w-fit text-[#808990] text-xs font-normal leading-[17.40px]">
              Capital
            </h5>
          </div>
        </div>

        <div className=" w-full justify-between items-start  inline-flex">
          <div className="w-full  flex-col justify-center items-center gap-0.5 inline-flex">
            <h3 className="w-full text-[#4a4f54] text-sm font-medium text-nowrap leading-[29px]">
              {data?.accuredInterest}%
            </h3>
            <h5 className="w-full text-[#808990] text-xs font-normal leading-[17.40px]">
              Accrued Interest
            </h5>
          </div>
          <div className="w-full  flex-col justify-end items-end gap-0.5 inline-flex">
            <h3 className="w-fit text-[#4a4f54] text-sm font-medium text-nowrap leading-[29px]">
              {formatDate(new Date(data?.maturityDate))}
            </h3>
            <h5 className="w-fit text-[#808990] text-xs font-normal leading-[17.40px]">
              Maturity Date
            </h5>
          </div>
        </div>
      </div>
      <div className="h-px w-full bg-[#cdd1d4]" />
      {/* BUTTON */}
      <div className="w-full justify-end items-center gap-2.5 inline-flex overflow-hidden">
        <button
          onClick={() => {
            action(data);
          }}
          className="text-[#0c4cff] text-sm font-medium leading-tight"
        >
          View Loan
        </button>
      </div>
    </div>
  );
}
