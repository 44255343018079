import { useFormik } from "formik";
import { ReactComponent as Logo } from "../Images/Logo-with-text.svg";
import { Link } from "react-router-dom";
import { visitorSubscribeValidator } from "../../utils/services/validation";
import { useState } from "react";
import MiscRequest from "../../utils/services/miscRequests";
import { toast } from "react-toastify";
import WhatsApp from "../Images/whatsapp.svg";
import mail from "../Images/mail-02.svg";
import call from "../Images/call.svg";
import cancel from "../Images/Cancel.svg";
import triangle from "../Images/triangleHelp.svg";

const contact = [
  {
    id: 1,
    name: "Open WhatsApp",
    icon: WhatsApp,
    type: "whatsapp",
    contact: "+2348163153270",
  },
  {
    id: 2,
    name: "Send an Email",
    icon: mail,
    type: "email",
    contact: "hello@ijefund.com",
  },
  {
    id: 3,
    name: "Call Us",
    icon: call,
    type: "tel",
    contact: "+2348163153270",
  },
];
function Footer() {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [help, setHelp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const date = new Date().getFullYear();
  const formik = useFormik({
    validationSchema: visitorSubscribeValidator,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);

      await MiscRequest.subscribe(values.email).then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          console.log("error");
          // setErrorMessage(res.data.message);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          setSuccess(false);
        }
      });
    },
    enableReinitialize: true,
  });
  return (
    <>
      <div className="mb-[-80px] relative  w-full h-fit py-[96px] bg-[#3A3C3F] flex-col gap-[64px] 100:px-[32px] md:px-[80px]  flex justify-center items-center">
        <div className=" 100:flex-col 100:gap-[48px] lg:gap-0 lg:flex-row flex justify-between items-start w-full">
          <div className=" flex text-[#fff] w-full flex-col justify-start items-start gap-[32px]">
            <div className=" w-full xl:justify-start xl:items-start  flex 100:justify-center 100:items-center">
              {/* <img className=" !text-white " src={logo} alt="IJE" /> */}
              <Logo style={{ color: "#fff" }} />
            </div>
            <nav className=" flex gap-[32px] 100:flex-col text-left md:flex-row justify-start 100:items-start md:items-center">
              {/* <h4 className="text-neutral-300 text-base font-medium leading-normal">Overview</h4>
              <h4 className="text-neutral-300 text-base font-medium leading-normal">Features</h4>
              <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Pricing</h4> */}
              <Link onClick={() => window.scrollTo(0, 0)} to="/faqs">
                <h4 className="text-neutral-300  hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">FAQs</h4>
              </Link>
              {help && (
                <>
                  <div className=" absolute 100:left-4 phone:left-[40px] 100:bottom-32 phone:bottom-16 lg:left-[150px] lg:bottom-28  justify-start items-start  flex flex-col 100:w-[90%] phone:w-[416px] h-full">
                    {" "}
                    <div className="h-fit w-full px-6 py-5 bg-[#0a205c] rounded-xl shadow flex-col justify-start items-center gap-2.5 inline-flex">
                      <div className="w-full justify-end items-center flex">
                        <div onClick={() => setHelp(false)} className="w-[36px] cursor-pointer h-[36px] bg-[#103a9f] rounded-[100px] flex-col justify-center items-center flex">
                          <img className=" " src={cancel} alt="Cancel" />
                        </div>
                      </div>
                      <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                        <div className="w-full flex-col justify-center items-center gap-2 flex">
                          <h2 className="self-stretch text-center text-[#f5f6f6] text-lg font-medium font-inter leading-7">How can we assist you?</h2>
                          <p className="self-stretch text-center text-[#aab0b6] text-sm font-normal font-inter leading-tight">
                            Choose how you'd like to reach us—via WhatsApp, email, or a direct call. <span className="text-[#e5e7e8] text-sm font-medium ">We're here to help!</span>
                          </p>
                        </div>
                        <div className="w-full py-2.5 100:justify-center sm:justify-start 100:flex-wrap sm:flex-nowrap items-start gap-3 inline-flex">
                          {contact.map((item) => (
                            <>
                              {item.type === "email" ? (
                                <a href={`mailto:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : item.type === "tel" ? (
                                <a href={`tel:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : item.type === "whatsapp" ? (
                                <a
                                  href={`https://wa.me/${item.contact.replace("+", "")}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  key={item.id}
                                  className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5"
                                >
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : (
                                <div key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="ttext-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <img className="w-[70px] scale-y-[-1] mt-[-30px] h-[70px]" src={triangle} alt="" />
                  </div>
                </>
              )}
              <button onClick={() => setHelp(!help)} className={` ${help ? "  text-[#0C4CFF] underline" : "text-neutral-300"}  text-base font-medium leading-normal`}>
                <h4 className="">Need Help</h4>
              </button>
              <Link onClick={() => window.scrollTo(0, 0)} to="/legal/termsofuse">
                <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Terms</h4>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/legal/privacypolicy">
                <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Privacy</h4>
              </Link>
            </nav>
          </div>
          <div className=" 100:w-full phone:w-fit flex gap-[16px] flex-col justify-start items-start">
            <h4 className=" font-semibold text-white text-[14px] leading-[20px]">Stay up to date</h4>
            <form className="flex w-full 100:flex-col phone:flex-row  justify-start items-start gap-[16px]">
              <input
                required
                className=" rounded-[8px] outline-[#0C4CFF] border border-[#AAB0B6] text-[#3A3C3F] placeholder:text-[#6C757D] text-[16px] font-normal px-[14px] 100:w-full bg-[#fff] phone:w-[230px] h-[44px] "
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                placeholder="Enter your email"
                onBlur={formik.handleBlur("email")}
              />{" "}
              <button onClick={formik.handleSubmit} className="bg-[#0C4CFF] 100:w-full phone:w-fit border border-[#0C4CFF] text-[16px] text-[#fff] font-medium rounded-[8px] px-[18px] py-[10px] ">
                {isLoading ? "Loading..." : "Subscribe"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full  border-t-gray-600 border-t pt-8 justify-center items-center gap-8 flex">
          <section className=" 100:flex-col lg:flex-row  100:gap-[20px] flex 100:justify-center 100:items-center lg:justify-between lg:items-start w-full">
            <h5 className="grow shrink basis-0 text-gray-500 text-base font-normal leading-normal">© {date} Ije. All rights reserved.</h5>
            <div className="w-fit justify-start items-start gap-4 inline-flex">
              <a href="https://www.linkedin.com/company/ijefund/">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Linkedin</h5>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61562789349575">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Facebook</h5>
              </a>
              <a href="https://www.instagram.com/ije_fund/?utm_source=ig_web_button_share_sheet">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Instagram</h5>
              </a>
              <a href="https://x.com/ije_fund/">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">
                  X <span className="text-neutral-400 hover:text-[#0C4CFF]">(Formerly Twitter)</span>
                </h5>
              </a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Footer;
