import {
  CurrencyFormatter,
  formatDate,
  formatTime,
} from "../../../../utils/helpers/helper";

function RequestHistory({ data }) {
  const withdrawals = [
    {
      id: 1,
      status: "Liquidated",
      transactionId: "#0089890",
      amountRequested: "₦30,000",
      transactionType: "Withdrawal",
      liquidatedLoan: "$30,000",
      investmentType: "Investment 1",
      dateOfRequest: "10th July 2024",
      dateOfDisbursment: "10th July",
      timeDisbursed: "10:00 PM",
    },
    {
      id: 2,
      status: "Liquidated",
      transactionId: "#0089890",
      amountRequested: "₦30,000",
      transactionType: "Withdrawal",
      liquidatedLoan: "$30,000",
      investmentType: "Investment 1",
      dateOfRequest: "10th July 2024",
      dateOfDisbursment: "10th July",
      timeDisbursed: "10:00 PM",
    },
  ];
  return (
    // <div className="w-full justify-start  flex-1 flex 100:flex-wrap items-center  xl:flex-nowrap gap-4 i">
    <div className=" w-full flex-wrap justify-start items-start gap-6 inline-flex overflow-hidden">
      {data.map((item) => (
        <div
          key={item.id}
          className="flex-grow 100:min-w-[325px] xl:min-w-[525px]  inline-flex self-stretch h-fit p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)]  border border-neutral-300 flex-col justify-start items-start gap-6 "
        >
          <div className="w-full justify-between items-center gap-2.5 inline-flex">
            <h3 className="self-stretch text-neutral-700 text-base font-medium leading-normal">
              Transaction Details
            </h3>
            <div
              className={`mix-blend-multiply w-fit px-2 py-0.5 ${
                item.status === "Pending"
                  ? "bg-yellow-50 text-amber-700"
                  : item.status === "SUCCESS"
                  ? " bg-[#F1FCF2] text-[#1F7634]"
                  : item.status === "Liquidated"
                  ? "text-neutral-600 bg-neutral-100"
                  : "bg-red-100 text-red-800"
              }  rounded-2xl justify-center items-center flex text-center  text-xs font-medium leading-none`}
            >
              {item.status}
            </div>
          </div>
          <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Transaction ID
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {item.refNo}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Amount Requested
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {/* {item.amountRequested} */}
                {CurrencyFormatter(item?.amount || 0)}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Transaction Type
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {item.type}
              </h3>
            </div>
            {/* <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Liquidated Loan
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {item.liquidatedLoan}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" /> */}
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Reason
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {item?.details}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Date of Request
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {/* {item.dateOfRequest} */}
                {formatDate(new Date(item?.createdDate))}{" "}
                {formatTime(new Date(item?.createdDate))}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Date Disbursed
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {/* {item.dateOfDisbursment} */}
                {item?.modifiyDate
                  ? formatDate(new Date(item?.modifiyDate))
                  : ""}
              </h3>
            </div>
            <div className="self-stretch h-px bg-gray-200" />
            <div className="w-full justify-between items-center inline-flex">
              <h3 className="text-gray-500 text-xs font-normal leading-none">
                Time Disbursed
              </h3>
              <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                {item?.modifiyDate
                  ? formatTime(new Date(item?.modifiyDate))
                  : ""}
              </h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RequestHistory;
