import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/lenderManagement.svg";
import { useEffect, useState } from "react";
import OnboarderdLenders from "./LendersManagement/OnboarderdLenders";
import AllLoans from "./LendersManagement/AllLoans";
import ActiveLoan from "./LendersManagement/ActiveLoan";
import MaturedLoans from "./LendersManagement/MaturedLoans";
import WithdrawlRequest from "./LendersManagement/WithdrawlRequest";
import AdminRequest from "../../utils/services/adminRequests";
import { CurrencyFormatter } from "../../utils/helpers/helper";
import FilterComp from "../shared/input/FilterComp";

function LenderManagement() {
  const [allLenders, setAllLenders] = useState("onboarded-lenders");
  const [withdrawal, setWithdrawal] = useState(true);

  const [isData, setData] = useState({});
  const [allTrans, setTrans] = useState([]);
  const [active, setActive] = useState([]);
  const [matured, setMatured] = useState([]);
  const [isFilterd, setFiltered] = useState("All");
  const [withdrawalRequest, setWithdrawalRequest] = useState([]);
  const handleAllLendersStatus = (menu) => {
    setAllLenders(menu);
  };

  const getMainData = async () => {
    await AdminRequest.getLenderDash().then((res) => {
      console.log("lender res", res);

      setData(res?.data?.result);
    });
  };

  const getAllTrans = async () => {
    await AdminRequest.getAllLenderTransactions().then((res) => {
      console.log("user trans", res);

      if (res?.data?.result?.all_loans.length !== 0) {
        setTrans(res?.data?.result?.all_loans);
      }

      if (res?.data?.result?.all_active_loans.length !== 0) {
        setActive(res?.data?.result?.all_active_loans);
      }

      if (res?.data?.result?.all_matured_loans.length !== 0) {
        setMatured(res?.data?.result?.all_matured_loans);
      }

      if (res?.data?.result?.all_withdrawal_request.length !== 0) {
        setWithdrawalRequest(res?.data?.result?.all_withdrawal_request);
      }
      // setUser(res.data.result);
    });
  };
  useEffect(() => {
    getMainData();
    getAllTrans();
  }, []);

  const lendersOverview = [
    {
      id: 1,
      name: "Total No of Lenders",
      icon: <Icon className=" text-[#0C4AFF] w-6 h-6" />,
      amount: `${isData?.total_lenders || 0}`,
    },
    {
      id: 2,
      name: "Total Funds Lent",
      icon: <Icon className=" text-[#0C4AFF] w-6 h-6" />,
      amount: `${CurrencyFormatter(isData?.all_loans_lent || 0)}`,
    },
    {
      id: 3,
      name: "Total Active Loans",
      icon: <Icon className=" text-[#0C4AFF] w-6 h-6" />,
      amount: `${isData?.all_active_loans || 0}`,
    },
    {
      id: 4,
      name: "Total Interest earned",
      icon: <Icon className=" text-[#0C4AFF] w-6 h-6" />,
      // amount: `${isData?.interest_earned || 0} %`,
      amount: `${CurrencyFormatter(isData?.interest_earned || 0, "$")}`,
    },
  ];

  const filterFunc = (data) => {
    console.log("data", data);
    setFiltered(data);
  };
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">
            Lenders Management
          </h3>
          {/* LENDERS CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              Loan Overview
            </h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {lendersOverview.map((item) => (
                <div
                  key={item.id}
                  className=" h-fit  flex-grow min-w-[202.6px]  p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start gap-6 inline-flex"
                >
                  <div className=" w-fit flex-col justify-between items-start gap-2.5 inline-flex">
                    <h4 className="text-[#6C757D] text-sm font-normal leading-tight">
                      {item.name}
                    </h4>
                    <h3 className="text-[#4A4F54] whitespace-nowrap text-2xl font-semibold ">
                      {item.amount}
                    </h3>
                  </div>
                  <div
                    className={`w-12 h-12 border-[#E8F7FF] bg-[#D5EFFF]  flex justify-center items-center rounded-[28px]  border-4 `}
                  >
                    <span className="flex justify-center items-center w-[20px] h-[20px]">
                      {item.icon}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
            <div className="md:w-fit  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
              <button
                className={`h-[38px] px-[4px] pb-[8px] ${
                  allLenders === "onboarded-lenders"
                    ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                    : ""
                }`}
                onClick={() => handleAllLendersStatus("onboarded-lenders")}
              >
                Onboarded Lenders
              </button>
              <button
                className={`h-[38px] px-[4px] pb-[8px] ${
                  allLenders === "all-loans"
                    ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                    : ""
                }`}
                onClick={() => handleAllLendersStatus("all-loans")}
              >
                All Loans
              </button>
              {/* <button
                className={`h-[38px] px-[4px] pb-[8px] ${
                  allLenders === "active-loan"
                    ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                    : ""
                }`}
                onClick={() => handleAllLendersStatus("active-loan")}
              >
                Active Loans
              </button> */}
              {/* <button
                className={`h-[38px] px-[4px] pb-[8px] ${
                  allLenders === "matured-loans"
                    ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                    : ""
                }`}
                onClick={() => handleAllLendersStatus("matured-loans")}
              >
                Matured Loans
              </button> */}
              <button
                className={`h-[38px] flex w-fit justify-center items-center gap-2 px-[4px] pb-[8px] ${
                  allLenders === "withdrawal-requests"
                    ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                    : ""
                }`}
                onClick={() => handleAllLendersStatus("withdrawal-requests")}
              >
                Withdrawal Requests
                {withdrawal && (
                  <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                    {withdrawalRequest?.length}
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className=" w-full">
            {allLenders === "onboarded-lenders" && (
              <div className=" w-full flex-col gap-[16px] justify-start items-center  inline-flex">
                <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                  Onboarded Lenders
                </h5>

                <div className="w-full ">
                  <OnboarderdLenders />
                </div>
              </div>
            )}
            {allLenders === "all-loans" && (
              <div className=" w-full  gap-[16px] flex-col justify-start items-center  inline-flex">
                <div className=" w-full flex justify-between items-center">
                  <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                    {isFilterd} Loans
                  </h5>
                  <FilterComp
                    title={"Filter by status"}
                    options={[
                      "All",
                      "Active",
                      // "Matured"
                    ]}
                    onFilter={filterFunc}
                    isFiltered={isFilterd}
                  />
                </div>

                <div className="w-full ">
                  {isFilterd === "All" ? (
                    <ActiveLoan data={allTrans} />
                  ) : isFilterd === "Active" ? (
                    <ActiveLoan data={active} />
                  ) : (
                    <MaturedLoans />
                  )}
                </div>
              </div>
            )}

            
            {allLenders === "active-loan" && (
              <div className=" w-full flex-col gap-[16px] justify-start items-center  inline-flex">
                <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                  Active Loans
                </h5>

                <div className="w-full ">
                  <ActiveLoan data={active} />

                  {/* <AllLoans data={active} /> */}
                </div>
              </div>
            )}
            {allLenders === "matured-loans" && (
              <div className=" w-full flex-col gap-[16px] justify-start items-center  inline-flex">
                <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                  Matured Loans
                </h5>

                <div className="w-full ">
                  <MaturedLoans />
                </div>
              </div>
            )}
            {allLenders === "withdrawal-requests" && (
              <div className=" w-full flex-col gap-[16px] justify-start items-center  inline-flex">
                <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                  Withdrawal Request
                </h5>

                <div className="w-full ">
                  <WithdrawlRequest data={withdrawalRequest} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LenderManagement;
