import { Link } from "react-router-dom";
import cancel from "../../Images/Cancel.svg";
import {
  CurrencyFormatter,
  downloadPDF,
  formatDate,
} from "../../../utils/helpers/helper";
import { useEffect, useState } from "react";
import LenderReqeusts from "../../../utils/services/lenderRequests";
import Deposit from "./deposit";
import { toast } from "react-toastify";
const ViewInvestment = ({ closeModal, data }) => {
  //   console.log("isada", data);
  const [transData, setTransData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const fetchData = async () => {
    if (data)
      await LenderReqeusts.getInvestmentTransaction(data?.refNo).then((res) => {
        console.log("is res", res);

        setTransData(res.data.result.transactions);
      });
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const modalData = [
    {
      id: 1,
      title: "Total Capital Lent",
      figure: CurrencyFormatter(data?.amount || 0, "$"),
      date: ` ${formatDate(new Date(data?.createdDate))} - ${formatDate(
        new Date(data?.maturityDate)
      )}`,
    },
    {
      id: 2,
      title: "Total Accrued Interest",
      figure: `${data?.accuredInterest || 0} %`,
      date: ` ${formatDate(new Date(data?.createdDate))} - ${formatDate(
        new Date(data?.maturityDate)
      )}`,
    },
    {
      id: 3,
      title: "Total Withdrawals Made",
      figure: CurrencyFormatter(data?.amountPayout || 0, "$"),
      date: ` ${formatDate(new Date(data?.createdDate))} - ${formatDate(
        new Date(data?.maturityDate)
      )}`,
    },
    {
      id: 4,
      title: "Next Interest Accrual Date",
      figure: formatDate(new Date(data?.nextMaturityDate)),
      //   date: "May  - Dec 2024",
    },
  ];

  const recommend = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4545 10.8337C17.1135 9.88883 17.5 8.73966 17.5 7.50033C17.5 4.27867 14.8884 1.66699 11.6667 1.66699C8.44504 1.66699 5.83337 4.27866 5.83337 7.50033C5.83337 8.39499 6.03478 9.24258 6.39473 10.0003"
        stroke="#F5F6F6"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 5.00033C10.7462 5.00033 10 5.55997 10 6.25033C10 6.94068 10.7462 7.50033 11.6667 7.50033C12.5872 7.50033 13.3333 8.05997 13.3333 8.75033C13.3333 9.44066 12.5872 10.0003 11.6667 10.0003M11.6667 5.00033C12.3923 5.00033 13.0097 5.34816 13.2385 5.83366M11.6667 5.00033V4.16699M11.6667 10.0003C10.941 10.0003 10.3237 9.65249 10.0948 9.16699M11.6667 10.0003V10.8337"
        stroke="#F5F6F6"
        stroke-width="1.25"
        stroke-linecap="round"
      />
      <path
        d="M2.5 11.667H4.49568C4.74081 11.667 4.98257 11.7222 5.20181 11.8283L6.90346 12.6517C7.1227 12.7577 7.36446 12.8129 7.60958 12.8129H8.47842C9.31875 12.8129 10 13.4722 10 14.2853C10 14.3182 9.9775 14.3471 9.94483 14.3561L7.82739 14.9416C7.44756 15.0466 7.04083 15.01 6.6875 14.839L4.86843 13.9589M10 13.7503L13.8273 12.5744C14.5058 12.363 15.2392 12.6137 15.6642 13.2022C15.9716 13.6277 15.8464 14.2372 15.3987 14.4955L9.13575 18.1091C8.73742 18.3389 8.26745 18.395 7.8293 18.265L2.5 16.6836"
        stroke="#F5F6F6"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  console.log("isda", data);

  const isWithdrawal =
    data?.accuredInterest === 0 || data?.status === "LIQUIDATED" ? false : true;

  // console.log("isda", isWithdrawal);

  const generateReciept = async () => {
    setLoading(true);
    await LenderReqeusts.getLendingCertificate(data.id)
      .then((res) => {
        console.log("is respone", res?.result);
        // setLoading(false);

        if (res.data.statusCode === 200 && res.data.result) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          downloadPDF(res.data.result);
        } else {
          toast.error(res?.data?.message || "an error occured", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
        <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
          <div
            onClick={closeModal}
            className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
          >
            <img className=" " src={cancel} alt="Cancel" />
          </div>
        </section>
        <section className=" 100:w-full h-full iphone:w-full flex justify-center  items-center">
          <div className=" 100:w-[90%] lg:w-[80%] h-[78%] overflow-y-scroll 100:p-4  md:p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
            <div className="w-full 100:p-4 md:p-8 flex-col justify-start items-center gap-6 inline-flex overflow-y-scroll">
              <div className="w-full 100:flex-col phone:flex-row 100:gap-4 justify-between 100:justify-end 100:items-end md:items-center inline-flex">
                <div className="w-full shrink flex-col justify-start items-start gap-1 inline-flex">
                  <h3 className="self-stretch text-[#3a3c3f] text-xl font-semibold  leading-[29px]">
                    Loan Details
                  </h3>
                  <h5 className="self-stretch 100:hidden md:flex  text-[#6c757d] text-base font-normal  leading-normal">
                    Track your performance and gain valuable insights into your
                    loan.
                  </h5>
                </div>

                {isWithdrawal === true && (
                  <Link to={`/lender/withdrawal/${data?.id}`}>
                    <button className="h-fit w-fit text-nowrap  px-4 py-2.5 bg-[#e8f7ff] rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] justify-center items-center flex text-[#4a4f54] text-sm font-medium  leading-tight">
                      Withdraw Funds
                    </button>
                  </Link>
                )}

                <button
                  onClick={() => generateReciept()}
                  className="h-fit w-fit text-nowrap  px-4 py-2.5 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] justify-center items-center flex text-[#4a4f54] text-sm font-medium  leading-tight"
                >
                  {isLoading ? "Generating..." : "Generate Certificate"}
                </button>
              </div>
              <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] lg:flex-row justify-start items-start">
                {modalData.map((item) => (
                  <div
                    key={item.id}
                    className="lg:h-[116px] 100:h-fit flex-grow 1 100:min-w-[200px] md:w-[23.84%] p-6 bg-white rounded-lg shadow-[0px_2px_5px_0px_rgba(16,24,40,0.16)]  border border-[#cdd1d4] flex-col justify-start items-start gap-4 inline-flex"
                  >
                    <div className="w-full  flex-col text-nowrap justify-start items-start gap-[6px] inline-flex">
                      <h4 className="text-[#4a4f54] w-full text-xs font-normal leading-tight">
                        {item.title}
                      </h4>
                      <h3 className="text-[#3a3c3f] w-full 100:text-base md:text-2xl font-semibold">
                        {item.figure}
                      </h3>
                      <h5 className="text-[#808990] w-full text-[10px] font-normal">
                        {item.date}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full justify-start items-start gap-4 inline-flex 100:flex-col lg:flex-row">
                <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                  <h3 className="text-[#4a4f54] text-base font-medium  leading-normal">
                    Loan Breakdown
                  </h3>
                  <Deposit data={transData} />
                </div>
                <div className="w-full   100:flex-col lg:flex-row justify-start items-start gap-4 inline-flex">
                  <div className="w-full h-[261px] shrink grow basis-1 px-6 pt-4 pb-6 bg-[#e8f7ff] rounded-lg shadow-[0px_2px_5px_0px_rgba(16,24,40,0.16)] flex-col justify-center items-start gap-6 inline-flex">
                    <div className="w-full h-[25px] px-6 py-1 bg-[#d5efff] rounded-[999px] flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-[#3a3c3f] text-xs font-medium  leading-[17.40px]">
                        Recommendation
                      </h3>
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2.5 inline-flex">
                      <div className="h-10 w-10 p-2.5 bg-[#063ccd] rounded-[999px] justify-center items-center gap-1 inline-flex">
                        {recommend}
                      </div>
                      <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                        <h2 className="self-stretch text-center text-[#242628] text-base font-semibold ">
                          Boost Your Loan
                        </h2>
                        <p className="grow shrink basis-0 text-center text-[#6c757d] text-[10px] font-normal  leading-[14.50px]">
                          Increase your investment to $30,000 and earn an
                          additional $3,600 annually in interest!
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                      <div className="h-px w-full bg-[#cdd1d4]" />
                      <Link className=" w-full " to="/lender/lend">
                        <button className="w-full h-8 px-2 py-1.5 bg-white rounded-lg border border-[#cdd1d4] justify-center items-center flex text-[#0c4cff] text-xs font-semibold  leading-tight">
                          Lend More Now
                        </button>
                      </Link>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ViewInvestment;
