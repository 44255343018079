function HowIjeWorks() {
  const student = [
    {
      id: 1,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.417 18.4933H5.49272C4.2049 18.4933 3.18058 17.8667 2.26088 16.9905C0.378129 15.1968 3.46933 13.7633 4.6483 13.0613C6.39897 12.0189 8.44769 11.6366 10.417 11.9143C11.1316 12.0151 11.8275 12.2028 12.5003 12.4774"
            stroke="#0C4AFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M13.75 5.57666C13.75 7.64773 12.0711 9.32666 10 9.32666C7.92893 9.32666 6.25 7.64773 6.25 5.57666C6.25 3.50559 7.92893 1.82666 10 1.82666C12.0711 1.82666 13.75 3.50559 13.75 5.57666Z" stroke="#0C4AFF" stroke-width="1.5" />
          <path d="M15.4167 18.4932L15.4167 12.6599M12.5 15.5766H18.3333" stroke="#0C4AFF" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      ),
      title: "Sign Up & Get Approved",
      description: "Register as a student and submit all required documents for verification. Once approved, you can start raising funds.",
    },
    {
      id: 2,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4385 2.58585L6.8946 5.24709C6.46792 5.4519 6.01203 5.50321 5.54729 5.3991C5.24314 5.33097 5.09105 5.2969 4.96859 5.28292C3.44786 5.10926 2.5 6.31285 2.5 7.69688V8.45644C2.5 9.84047 3.44786 11.0441 4.96859 10.8704C5.09105 10.8564 5.24315 10.8223 5.54729 10.7542C6.01203 10.6501 6.46793 10.7014 6.8946 10.9062L12.4385 13.5675C13.7112 14.1784 14.3475 14.4838 15.057 14.2457C15.7664 14.0077 16.01 13.4968 16.497 12.475C17.8343 9.66929 17.8343 6.48403 16.497 3.6783C16.01 2.65654 15.7664 2.14567 15.057 1.90758C14.3475 1.66949 13.7112 1.97494 12.4385 2.58585Z"
            stroke="#0C4AFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.54805 17.4691L8.30529 18.4934C5.50397 16.2717 5.8462 15.2122 5.8462 10.9934H6.79105C7.17449 13.3775 8.07894 14.5068 9.32696 15.3243C10.0957 15.8278 10.2542 16.8871 9.54805 17.4691Z"
            stroke="#0C4AFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M6.25 10.5767V5.57666" stroke="#0C4AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      title: "Create Campaign",
      description: "Launch a campaign to collect donations from family, friends, and supporters. You can choose to keep it private or public to maximize contributions.",
    },
    {
      id: 3,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.4541 10.9933C17.1132 10.0485 17.4997 8.89933 17.4997 7.65999C17.4997 4.43834 14.888 1.82666 11.6663 1.82666C8.44467 1.82666 5.83301 4.43833 5.83301 7.65999C5.83301 8.55466 6.03442 9.40224 6.39437 10.16"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.6667 5.15999C10.7462 5.15999 10 5.71964 10 6.40999C10 7.10035 10.7462 7.65999 11.6667 7.65999C12.5872 7.65999 13.3333 8.21964 13.3333 8.90999C13.3333 9.60033 12.5872 10.16 11.6667 10.16M11.6667 5.15999C12.3923 5.15999 13.0097 5.50783 13.2385 5.99333M11.6667 5.15999V4.32666M11.6667 10.16C10.941 10.16 10.3237 9.81216 10.0948 9.32666M11.6667 10.16V10.9933"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
          />
          <path
            d="M2.5 11.8267H4.49568C4.74081 11.8267 4.98257 11.8819 5.20181 11.988L6.90346 12.8113C7.1227 12.9174 7.36446 12.9726 7.60958 12.9726H8.47842C9.31875 12.9726 10 13.6318 10 14.445C10 14.4778 9.9775 14.5067 9.94483 14.5157L7.82739 15.1012C7.44756 15.2062 7.04083 15.1697 6.6875 14.9987L4.86843 14.1186M10 13.91L13.8273 12.7341C14.5058 12.5227 15.2392 12.7733 15.6642 13.3619C15.9716 13.7874 15.8464 14.3968 15.3987 14.6552L9.13575 18.2687C8.73742 18.4986 8.26745 18.5547 7.8293 18.4247L2.5 16.8432"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      title: "Access a Loan",
      description: " If your campaign doesn’t fully cover your tuition and living expenses, Ije lenders will provide the rest as a loan. Loans come with flexible repayment terms of up to 5 years to ease financial burden.",
    },
    {
      id: 4,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.66699 6.82658C1.66699 7.94473 8.41274 10.9932 9.98874 10.9932C11.5647 10.9932 18.3105 7.94473 18.3105 6.82658C18.3105 5.70843 11.5647 2.65991 9.98874 2.65991C8.41274 2.65991 1.66699 5.70843 1.66699 6.82658Z"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.99512 9.32666L5.19938 14.0182C5.20346 14.1122 5.21362 14.2062 5.24084 14.2962C5.32498 14.5744 5.48007 14.8272 5.71667 14.997C7.56788 16.3254 12.4084 16.3254 14.2596 14.997C14.4963 14.8272 14.6513 14.5744 14.7355 14.2962C14.7627 14.2062 14.7728 14.1122 14.777 14.0182L14.9812 9.32666"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.0615 8.07666V13.91M17.0615 13.91C16.4015 15.1152 16.1096 15.761 15.8132 16.8267C15.7489 17.2058 15.8 17.3969 16.0613 17.5666C16.1675 17.6355 16.295 17.66 16.4215 17.66H17.6885C17.8233 17.66 17.9592 17.6319 18.0701 17.5554C18.313 17.3879 18.3755 17.2042 18.3097 16.8267C18.0499 15.8372 17.7189 15.1607 17.0615 13.91Z"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="10.1494" cy="6.90991" r="3.35" stroke="#0C4AFF" stroke-width="0.8" />
          <path
            d="M10.1496 5.3028C9.5578 5.3028 9.07812 5.66257 9.07812 6.10638C9.07812 6.55018 9.5578 6.90995 10.1496 6.90995C10.7413 6.90995 11.221 7.26972 11.221 7.71352C11.221 8.1573 10.7413 8.51709 10.1496 8.51709M10.1496 5.3028C10.6161 5.3028 11.0129 5.52641 11.16 5.83852M10.1496 5.3028V4.76709M10.1496 8.51709C9.68305 8.51709 9.2862 8.29348 9.13909 7.98138M10.1496 8.51709V9.0528"
            stroke="#0C4AFF"
            stroke-width="0.8"
            stroke-linecap="round"
          />
        </svg>
      ),
      title: "Tuition & Living Expenses Paid",
      description: "  Ije directly pays 100% of your tuition to your school. Once your visa is approved, you receive 30% of your living expenses upfront, with the rest sent after you relocate and settle in your new country.",
    },
  ];
  const lenders = [
    {
      id: 1,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.417 18.4933H5.49272C4.2049 18.4933 3.18058 17.8667 2.26088 16.9905C0.378129 15.1968 3.46933 13.7633 4.6483 13.0613C6.39897 12.0189 8.44769 11.6366 10.417 11.9143C11.1316 12.0151 11.8275 12.2028 12.5003 12.4774"
            stroke="#0C4AFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M13.75 5.57666C13.75 7.64773 12.0711 9.32666 10 9.32666C7.92893 9.32666 6.25 7.64773 6.25 5.57666C6.25 3.50559 7.92893 1.82666 10 1.82666C12.0711 1.82666 13.75 3.50559 13.75 5.57666Z" stroke="#0C4AFF" stroke-width="1.5" />
          <path d="M15.4167 18.4932L15.4167 12.6599M12.5 15.5766H18.3333" stroke="#0C4AFF" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      ),
      title: "Sign Up & Get Verified",
      description: "Register as a lender and complete your verification process. This ensures trust and security within the Ije platform.",
    },
    {
      id: 2,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.4541 10.9933C17.1132 10.0485 17.4997 8.89933 17.4997 7.65999C17.4997 4.43834 14.888 1.82666 11.6663 1.82666C8.44467 1.82666 5.83301 4.43833 5.83301 7.65999C5.83301 8.55466 6.03442 9.40224 6.39437 10.16"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.6667 5.15999C10.7462 5.15999 10 5.71964 10 6.40999C10 7.10035 10.7462 7.65999 11.6667 7.65999C12.5872 7.65999 13.3333 8.21964 13.3333 8.90999C13.3333 9.60033 12.5872 10.16 11.6667 10.16M11.6667 5.15999C12.3923 5.15999 13.0097 5.50783 13.2385 5.99333M11.6667 5.15999V4.32666M11.6667 10.16C10.941 10.16 10.3237 9.81216 10.0948 9.32666M11.6667 10.16V10.9933"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
          />
          <path
            d="M2.5 11.8267H4.49568C4.74081 11.8267 4.98257 11.8819 5.20181 11.988L6.90346 12.8113C7.1227 12.9174 7.36446 12.9726 7.60958 12.9726H8.47842C9.31875 12.9726 10 13.6318 10 14.445C10 14.4778 9.9775 14.5067 9.94483 14.5157L7.82739 15.1012C7.44756 15.2062 7.04083 15.1697 6.6875 14.9987L4.86843 14.1186M10 13.91L13.8273 12.7341C14.5058 12.5227 15.2392 12.7733 15.6642 13.3619C15.9716 13.7874 15.8464 14.3968 15.3987 14.6552L9.13575 18.2687C8.73742 18.4986 8.26745 18.5547 7.8293 18.4247L2.5 16.8432"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      title: "Provide Initial Support",
      description: "Start by contributing a minimum of $100 towards student loans. After this initial contribution, you can continue supporting students with additional funds, starting from $100 at any time. ",
    },
    {
      id: 3,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.83301 15.1598V13.4932M9.99967 15.1598V12.6598M14.1663 15.1598V10.9932M2.08301 10.1598C2.08301 6.42788 2.08301 4.56191 3.24237 3.40253C4.40175 2.24316 6.26772 2.24316 9.99967 2.24316C13.7316 2.24316 15.5976 2.24316 16.757 3.40253C17.9163 4.56191 17.9163 6.42788 17.9163 10.1598C17.9163 13.8917 17.9163 15.7577 16.757 16.9172C15.5976 18.0765 13.7316 18.0765 9.99967 18.0765C6.26772 18.0765 4.40175 18.0765 3.24237 16.9172C2.08301 15.7577 2.08301 13.8917 2.08301 10.1598Z"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.99316 9.73175C6.78908 9.79158 10.8614 9.5205 13.1778 5.84427M11.6599 5.40012L13.2228 5.14857C13.4133 5.12432 13.693 5.27471 13.7618 5.45398L14.175 6.81935"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      title: "Earn Returns & Monitor Activity",
      description: "Your contributions earn a fixed 12% annual return. Track your investments, repayments, and overall impact through your lender dashboard on the Ije platform.",
    },
    {
      id: 4,
      icon: (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.7791 11.7809L15.2205 8.7405C14.9792 7.4273 14.8586 6.77069 14.3808 6.38192C13.9031 5.99316 13.2168 5.99316 11.8443 5.99316H8.15571C6.78317 5.99316 6.0969 5.99316 5.61915 6.38192C5.1414 6.77069 5.02077 7.4273 4.77948 8.7405L4.22088 11.7809C3.72007 14.5067 3.46966 15.8696 4.24074 16.7647C5.01182 17.6598 6.43629 17.6598 9.28525 17.6598H10.7147C13.5637 17.6598 14.9882 17.6598 15.7592 16.7647C16.5303 15.8696 16.2799 14.5067 15.7791 11.7809Z"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
          />
          <path
            d="M10.1667 9.15999C9.24617 9.15999 8.5 9.71964 8.5 10.41C8.5 11.1004 9.24617 11.66 10.1667 11.66C11.0872 11.66 11.8333 12.2196 11.8333 12.91C11.8333 13.6003 11.0872 14.16 10.1667 14.16M10.1667 9.15999C10.8923 9.15999 11.5097 9.50783 11.7385 9.99333M10.1667 9.15999V8.32666M10.1667 14.16C9.441 14.16 8.82367 13.8122 8.59483 13.3267M10.1667 14.16V14.9933"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
          />
          <path
            d="M17.5003 9.32658C17.631 9.26066 17.7446 9.17758 17.8455 9.07283C18.3337 8.56616 18.3337 7.75069 18.3337 6.11971C18.3337 4.48875 18.3337 3.67326 17.8455 3.16659C17.3573 2.65991 16.5717 2.65991 15.0003 2.65991H5.00033C3.42898 2.65991 2.6433 2.65991 2.15515 3.16659C1.66699 3.67326 1.66699 4.48875 1.66699 6.11971C1.66699 7.75069 1.66699 8.56616 2.15515 9.07283C2.25606 9.17758 2.36968 9.26066 2.50033 9.32658"
            stroke="#0C4AFF"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      ),
      title: "Withdraw Returns or Continue Supporting",
      description: "Withdraw your earnings after 12 months, or reinvest to support more students and create a lasting impact.",
    },
  ];

  return (
    <>
      <div className=" w-full py-[96px] 100:px-[24px] md:px-[64px]  bg-blue-50 flex-col flex justify-center items-center">
        <div className="gap-[64px] 100:w-[90%] xl:w-[1280px] flex flex-col justify-start">
          <div className=" 100:w-full lg:w-[90%] xl:w-[1054px] gap-[20px] text-left flex flex-col items-start justify-start">
            <section className=" gap-[12px] text-left w-full flex flex-col ">
              <h5 className=" text-blue-700 text-base font-medium  leading-tight">How Ije Works</h5>

              <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">
                {" "}
                Getting Started with <span className="  text-[#0C4CFF]"> Ije</span> is Easy!
              </h2>
            </section>
            <p className="  leading-[30px] text-neutral-600 text-xl  w-full font-normal ">
              Got a postgraduate offer in the UK, US, Canada, or Europe? Ije provides a co-financing platform where lenders contribute to a pooled fund, and we disburse 100% of tuition and living expenses to eligible students.{" "}
              <span class="text-neutral-600  font-semibold ">Sign up as a student to secure funding or as a lender to support education while earning returns.</span>
            </p>
          </div>
          <div className=" w-full 100:flex-col lg:flex-row gap-6 flex justify-start items-start">
            <div className="h-fit w-full p-8 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-start gap-8 inline-flex">
              <div className="h-fit w-full  flex-col justify-start items-start gap-2 inline-flex">
                <h2 className="self-stretch text-neutral-600 text-2xl font-semibold  leading-9">For Students</h2>
                <div className=" bg-[#E5E7E8] w-full h-[1px]"> {/*intentional*/}</div>
              </div>
              {student.map((item, id) => (
                <div key={id} className="lg:w-[95%] 100:w-full h-fit flex 100:flex-col md:flex-row justify-start items-start gap-[20px]">
                  <div className="w-fit h-full flex flex-col justify-start items-center gap-2">
                    <div className="w-10 h-10 bg-[#D5EFFF] flex justify-center items-center rounded-3xl">{item.icon}</div>
                    {/* {id !== 3 && <div className="bg-[#D5EFFF] w-[3px] rounded-[4px] h-12">intentional</div>} */}
                  </div>
                  <div className="flex flex-col justify-start items-start text-left gap-[8px]">
                    <h4 className="text-neutral-600 text-lg font-semibold leading-relaxed">{item.title}</h4>
                    <p className="text-gray-500 text-base font-normal text-justify leading-normal">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="100:h-fit lg:h-fit xl:h-[700px] w-full p-8 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-start gap-8 inline-flex">
              <div className="h-fit w-full  flex-col justify-start items-start gap-2 inline-flex">
                <h2 className="self-stretch text-neutral-600 text-2xl font-semibold  leading-9">For Lenders</h2>
                <div className=" bg-[#E5E7E8] w-full h-[1px]"> {/*intentional*/}</div>
              </div>
              {lenders.map((item, id) => (
                <div key={id} className="lg:w-[95%] 100:flex-col md:flex-row 100:w-full h-fit flex justify-start items-start gap-[20px]">
                  <div className="w-fit h-full flex flex-col justify-start items-center gap-2">
                    <div className="w-10 h-10 bg-[#D5EFFF] flex justify-center items-center rounded-3xl">{item.icon}</div>
                    {/* {id !== 3 && <div className="bg-[#D5EFFF] w-[3px] rounded-[4px] h-12">intentional</div>} */}
                  </div>
                  <div className="flex flex-col justify-start items-start text-left gap-[8px]">
                    <h4 className="text-neutral-600 text-lg font-semibold leading-relaxed">{item.title}</h4>
                    <p className="text-gray-500 text-base font-normal text-justify leading-normal">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowIjeWorks;
