import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SideBar from "../SideBar";
import { ReactComponent as Back } from "../../Images/back.svg";
import { ReactComponent as Arrow } from "../../Images/LenderArrow.svg";
import { ReactComponent as Email } from "../../Images/mail-02.svg";
import user from "../../Images/userID.png";
import arrowUp from "../../Images/arrow-up-01.svg";
import arrowDown from "../../Images/arrow-down-01.svg";
import AllLoans from "./LendersDetails/AllLoans";
import ActiveLoan from "./LendersDetails/ActiveLoan";
import MaturedLoan from "./LendersDetails/MaturedLoan";
import WithdrawalRequest from "./LendersDetails/WithdrawalRequest";
import RequestHistory from "./LendersDetails/RequestHistory";
import AdminRequest from "../../../utils/services/adminRequests";
import { CurrencyFormatter, formatDate } from "../../../utils/helpers/helper";
import FilterComp from "../../shared/input/FilterComp";

function LendersDetails() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [allLoan, setAllLoans] = useState("all-loans");
  const [withdrawal, setWithdrawal] = useState(true);
  const [isUser, setUser] = useState({});
  const [allTrans, setTrans] = useState([]);
  const [active, setActive] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [withdrawalRequest, setWithdrawalRequest] = useState([]);
  const [matured, setMatured] = useState([]);
  const [isFilterd, setFiltered] = useState("All");
  const handleAllLoansStatus = (menu) => {
    setAllLoans(menu);
  };

  const getUserDetails = async () => {
    await AdminRequest.getLenderDetails(id).then((res) => {
      setUser(res.data.result);
    });
  };

  const getUserTrans = async () => {
    await AdminRequest.getLenderTransactions(id).then((res) => {
      console.log("user trans", res);

      if (res?.data?.result?.all_loans.length !== 0) {
        setTrans(res?.data?.result?.all_loans);
      }

      if (res?.data?.result?.all_active_loans.length !== 0) {
        setActive(res?.data?.result?.all_active_loans);
      }

      if (res?.data?.result?.all_mature_loans.length !== 0) {
        setMatured(res?.data?.result?.all_mature_loans);
      }
      if (res?.data?.result?.all_withdrawal_request.length !== 0) {
        // setWithdrawals(res?.data?.result?.all_withdrawal_success);

        // console.log("res?.data?.result?.all_withdrawal_request", res?.data?.result?.all_withdrawal_request)
        setWithdrawals(res?.data?.result?.all_withdrawal_request);
      }

      if (res?.data?.result?.all_withdrawal_success.length !== 0) {
        setWithdrawalRequest(res?.data?.result?.all_withdrawal_success);
      }
      // setUser(res.data.result);
    });
  };

  useEffect(() => {
    getUserDetails();
    getUserTrans();
  }, []);

  const basicInfo = [
    {
      id: 1,
      icon: (
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16602 4L5.19861 6.2849C6.68528 7.12726 7.31342 7.12726 8.80009 6.2849L12.8327 4"
            stroke="#808990"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
          <path
            d="M1.17521 8.3611C1.21335 10.1493 1.23242 11.0434 1.89224 11.7058C2.55206 12.3681 3.47038 12.3912 5.307 12.4373C6.43895 12.4658 7.55975 12.4658 8.6917 12.4373C10.5283 12.3912 11.4466 12.3681 12.1065 11.7058C12.7663 11.0434 12.7854 10.1493 12.8235 8.3611C12.8357 7.78612 12.8357 7.21454 12.8235 6.63956C12.7854 4.85133 12.7663 3.95721 12.1065 3.29488C11.4466 2.63254 10.5283 2.60947 8.6917 2.56332C7.55975 2.53488 6.43895 2.53488 5.307 2.56332C3.47037 2.60946 2.55206 2.63253 1.89224 3.29487C1.23241 3.95721 1.21335 4.85132 1.17521 6.63955C1.16295 7.21453 1.16295 7.78612 1.17521 8.3611Z"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
        </svg>
      ),
      type: "Email Address",
      details: `${isUser?.email}`,
    },
    {
      id: 2,
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2966_17323)">
            <path
              d="M2.45296 7.4667C1.89995 6.50242 1.63293 5.71502 1.47192 4.91687C1.2338 3.73641 1.77874 2.5833 2.68149 1.84752C3.06303 1.53655 3.5004 1.64279 3.72602 2.04756L4.23537 2.96136C4.6391 3.68566 4.84097 4.04781 4.80093 4.43175C4.76089 4.8157 4.48865 5.12841 3.94417 5.75383L2.45296 7.4667ZM2.45296 7.4667C3.57231 9.41849 5.32892 11.1761 7.28297 12.2967M7.28297 12.2967C8.24725 12.8497 9.03465 13.1167 9.83281 13.2778C11.0133 13.5159 12.1664 12.9709 12.9022 12.0682C13.2131 11.6866 13.1069 11.2493 12.7021 11.0237L11.7883 10.5143C11.064 10.1106 10.7019 9.90871 10.3179 9.94875C9.93397 9.98879 9.62126 10.261 8.99585 10.8055L7.28297 12.2967Z"
              stroke="#565C64"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2966_17323">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0.25 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      type: "Phone Number",
      details: `${isUser?.phoneNumber}`,
    },
    {
      id: 3,
      icon: (
        <svg
          width="19"
          height="15"
          viewBox="0 0 19 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 8.66699C6.93299 8.66699 8.5 7.09998 8.5 5.16699C8.5 3.23399 6.93299 1.66699 5 1.66699C3.067 1.66699 1.5 3.23399 1.5 5.16699C1.5 7.09998 3.067 8.66699 5 8.66699ZM5 8.66699V13.3337M3.25 11.5837H6.75"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 6.33398C15.933 6.33398 17.5 7.90099 17.5 9.83398C17.5 11.767 15.933 13.334 14 13.334C12.067 13.334 10.5 11.767 10.5 9.83398C10.5 7.90099 12.067 6.33398 14 6.33398ZM14 6.33398V1.66732M12.25 3.41732H15.75"
            stroke="#565C64"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      type: "Gender",
      details: `${isUser?.gender}`,
    },
    {
      id: 4,
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2966_17341)">
            <path
              d="M8.44369 12.9644C8.1907 13.2013 7.85256 13.3337 7.50065 13.3337C7.14874 13.3337 6.81061 13.2013 6.55762 12.9644C4.24093 10.7821 1.13628 8.34436 2.65032 4.80513C3.46895 2.8915 5.43403 1.66699 7.50065 1.66699C9.56727 1.66699 11.5323 2.8915 12.351 4.80513C13.8631 8.3399 10.7661 10.7897 8.44369 12.9644Z"
              stroke="#565C64"
              stroke-width="1.5"
            />
            <path
              d="M9.54232 6.91667C9.54232 8.04425 8.62823 8.95833 7.50065 8.95833C6.37307 8.95833 5.45898 8.04425 5.45898 6.91667C5.45898 5.78909 6.37307 4.875 7.50065 4.875C8.62823 4.875 9.54232 5.78909 9.54232 6.91667Z"
              stroke="#565C64"
              stroke-width="1.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_2966_17341">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      type: "House Address",
      details: `${isUser?.address}`,
    },
    {
      id: 5,
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2966_17350)">
            <path
              d="M1.91602 5.49895C1.91602 4.80118 2.19741 4.37355 2.77972 4.04949L5.17677 2.7155C6.43282 2.0165 7.06085 1.66699 7.74935 1.66699C8.43785 1.66699 9.06587 2.01649 10.3219 2.7155L12.719 4.04949C13.3013 4.37355 13.5827 4.80118 13.5827 5.49895C13.5827 5.68816 13.5827 5.78277 13.562 5.86054C13.4535 6.26917 13.0832 6.33366 12.7256 6.33366H2.7731C2.41551 6.33366 2.04524 6.26917 1.93668 5.86054C1.91602 5.78277 1.91602 5.68816 1.91602 5.49895Z"
              stroke="#565C64"
              stroke-width="1.5"
            />
            <path
              d="M7.74598 4.58398H7.75122"
              stroke="#565C64"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.08203 6.33398V11.2923M5.41536 6.33398V11.2923"
              stroke="#565C64"
              stroke-width="1.5"
            />
            <path
              d="M10.082 6.33398V11.2923M12.4154 6.33398V11.2923"
              stroke="#565C64"
              stroke-width="1.5"
            />
            <path
              d="M11.8327 11.292H3.66602C2.69952 11.292 1.91602 12.0755 1.91602 13.042C1.91602 13.2031 2.0466 13.3337 2.20768 13.3337H13.291C13.4521 13.3337 13.5827 13.2031 13.5827 13.042C13.5827 12.0755 12.7992 11.292 11.8327 11.292Z"
              stroke="#565C64"
              stroke-width="1.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_2966_17350">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0.75 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      type: "Bank Information",
      details: `${isUser?.lenderBankName}`,

      more: `${isUser?.lenderBankAccount}`,
    },
  ];
  const transactionCard = [
    {
      id: 1,
      title: "Total Lent Funds",
      amount: `${CurrencyFormatter(isUser?.total_lent || 0, "$")}`,
    },
    {
      id: 2,
      title: "Total Interest Earned",
      // amount: `${isUser?.total_interest || 0}%`,

      amount: `${CurrencyFormatter(isUser?.total_interest || 0, "$")}`,
    },
    {
      id: 3,
      title: "Total Active Loans",
      amount: `${isUser?.total_lent_active || 0}`,
    },
    {
      id: 4,
      title: "Total Liquidated Loans",
      amount: `${isUser?.total_liquidated || 0}`,
    },
    {
      id: 5,
      title: "Total Matured Loans",
      amount: `${isUser?.total_lent_mature || 0}`,
    },
  ];

  const filterFunc = (data) => {
    console.log("data", data);
    setFiltered(data);
  };


  // console.log("withdrawals", withdrawals)
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full gap-[32px]  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[20px] w-full">
            <div className="w-full text-nowrap justify-start items-start gap-5 inline-flex">
              <h3 className="w-full  flex justify-start items-center gap-[16px] text-[#3a3c3f] text-base font-medium  leading-normal ">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-[#aab0b6] text-sm font-medium leading-normal justify-start items-center gap-1 inline-flex"
                  to="/approver/lenders-management"
                >
                  <Arrow className=" 100:flex md:hidden" /> Lender Management{" "}
                  <Back className=" 100:hidden md:flex w-[14px] h-[14px] rotate-180" />
                </Link>
                <span className=" 100:hidden md:flex">Lender Details</span>
              </h3>
              <div className=" w-fit gap-2.5 flex justify-center items-center">
                <button className=" hover:bg-[#E5E7E8] w-fit h-fit border p-2 rounded-[8px] bg-white border-[#E5E7E8] ">
                  <Arrow />
                </button>
                <button className=" w-fit hover:bg-[#E5E7E8] h-fit border p-2 rounded-[8px] rotate-180 bg-white border-[#E5E7E8] ">
                  <Arrow />
                </button>
              </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-8 inline-flex overflow-hidden">
              <div className="h-fit w-full p-4 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] border border-gray-200 flex-col justify-start items-start gap-4 inline-flex overflow-hidden">
                <div className="w-full p-4 rounded-lg border border-neutral-300 flex-col justify-start items-start gap-6 inline-flex">
                  <div className="w-full 100:flex-col-reverse md:flex-row 100:justify-end 100:items-end md:justify-start md:items-center gap-6 inline-flex">
                    <div className="w-full justify-start items-center gap-4 inline-flex">
                      <img src={user} className=" w-[60px] h-[60px]" />
                      <div className="w-full flex-col justify-end items-start gap-1 inline-flex">
                        <h2 className="text-neutral-700 text-lg font-medium leading-relaxed">
                          {isUser?.firstName} {isUser?.lastName}
                        </h2>
                        <div className="text-gray-500 text-sm font-medium leading-tight">
                          Joined:{" "}
                          <span className="text-neutral-600 ">
                            {" "}
                            {formatDate(new Date(isUser?.createdAt))}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-fit h-full justify-start items-center gap-2.5 inline-flex">
                      <div className="px-3 py-1.5 bg-blue-50 rounded-full justify-center text-nowrap items-center gap-1 flex text-neutral-600 text-sm font-normal leading-tight">
                        <span className=" 100:hidden md:flex">User ID:</span>{" "}
                        <span class="text-neutral-600 font-medium ">
                          {" "}
                          {isUser?.userId}
                        </span>
                      </div>
                      <button
                        className={`w-[20px] h-[20px] flex justify-center items-center rounded-sm ${
                          open ? "bg-blue-50" : ""
                        } `}
                        onClick={() => setOpen(!open)}
                      >
                        {" "}
                        {open ? (
                          <img
                            className=" w-[14px] h-[14px]"
                            src={arrowUp}
                            alt="open"
                          />
                        ) : (
                          <img
                            className=" w-[14px] h-[14px]"
                            src={arrowDown}
                            alt="close"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  {open && (
                    <>
                      <div className=" w-full bg-[#F5F6F6] h-px" />
                      <div className="w-full 100:flex-wrap xl:flex-nowrap h-fit justify-start items-center gap-6 inline-flex">
                        {basicInfo.map((item) => (
                          <div
                            key={item.id}
                            className="xl:w-full 100:min-w-[200px] xl:min-w-0 100:flex-grow xl:flex-grow-0 flex-col justify-start items-start gap-2 inline-flex"
                          >
                            <div className="w-fit justify-start items-center gap-2 inline-flex">
                              <div>{item.icon}</div>
                              <h4 className="text-neutral-600 text-xs font-normal">
                                {item.type}
                              </h4>
                            </div>
                            <div className="w-full flex-col justify-center items-start gap-1 inline-flex">
                              <p className="self-stretch text-neutral-600 text-sm font-medium w-fit justify-start items-center gap-2.5 inline-flex leading-tight break-words">
                                {item.details} {item.bankIcon}
                              </p>

                              <p className="self-stretch text-neutral-600 text-sm font-medium  leading-tight">
                                {item.more}
                              </p>
                            </div>{" "}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div className="w-full justify-start flex-1 flex 100:flex-wrap items-center gap-4 xl:flex-nowrap">
                  {transactionCard.map((item) => (
                    <div className=" flex-grow min-w-[180px] p-6 bg-white rounded-lg shadow-[0px_2px_5px_0px_rgba(16,24,40,0.16)]  border border-neutral-300  flex-col justify-start items-start gap-1.5 flex">
                      <h5 className="text-neutral-600 text-nowrap text-sm font-normal leading-tight">
                        {item.title}
                      </h5>
                      <h2 className="grow shrink basis-0 text-neutral-700 text-xl font-semibold">
                        {item.amount}
                      </h2>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
                <div className="md:w-fit  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                  <button
                    className={`h-[38px] px-[4px] pb-[8px] ${
                      allLoan === "all-loans"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleAllLoansStatus("all-loans")}
                  >
                    All Loans
                  </button>
                  {/* <button
                    className={`h-[38px] px-[4px] pb-[8px] ${
                      allLoan === "active-loans"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleAllLoansStatus("active-loans")}
                  >
                    Active Loans
                  </button> */}

                  {/* <button
                    className={`h-[38px] px-[4px] pb-[8px] ${
                      allLoan === "matured-loans"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleAllLoansStatus("matured-loans")}
                  >
                    Matured Loans
                  </button> */}
                  <button
                    className={`h-[38px] flex w-fit justify-center items-center gap-2 px-[4px] pb-[8px] ${
                      allLoan === "withdrawal-requests"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleAllLoansStatus("withdrawal-requests")}
                  >
                    Withdrawal Requests
                    {withdrawal && (
                      <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                        {withdrawals?.length || 0}
                      </div>
                    )}
                  </button>
                  <button
                    className={`h-[38px] px-[4px] pb-[8px] ${
                      allLoan === "request-history"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleAllLoansStatus("request-history")}
                  >
                    Request History
                  </button>
                </div>
              </div>
              <div className=" w-full">
                {allLoan === "all-loans" && (
                  <div className="w-full ">
                    <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                      {isFilterd} Loans
                    </h5>

                    <div className=" w-full flex justify-end">
                      <FilterComp
                        title={"Filter by status"}
                        options={["All", "Active", "Matured"]}
                        onFilter={filterFunc}
                        isFiltered={isFilterd}
                      />
                    </div>

                    <div className="w-full ">
                      {isFilterd === "All" ? (
                        <AllLoans data={allTrans} />
                      ) : isFilterd === "Active" ? (
                        <AllLoans data={active} />
                      ) : (
                        // <ActiveLoan data={matured} />

                        <AllLoans data={matured} />
                      )}
                    </div>

                    {/* <AllLoans data={allTrans} /> */}
                  </div>
                )}
                {allLoan === "active-loans" && (
                  <div className="w-full ">
                    <ActiveLoan data={active} />
                  </div>
                )}
                {allLoan === "matured-loans" && (
                  <div className="w-full ">
                    <ActiveLoan data={matured} />
                  </div>
                )}
                {allLoan === "withdrawal-requests" && (
                  <div className="w-full ">
                    <WithdrawalRequest data={withdrawals} />
                  </div>
                )}
                {allLoan === "request-history" && (
                  <div className="w-full ">
                    <RequestHistory data={withdrawalRequest} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LendersDetails;
