import { useEffect, useState } from "react";
import {
  CurrencyFormatter,
  downloadPDF,
  formatDate,
} from "../../../../utils/helpers/helper";
import { ReactComponent as Search } from "../../../Images/search.svg";
import Footer from "../../Footer";
import Paginator from "../../../shared/Paginator";
import { ReactComponent as Pending } from "../../../Images/pending.svg";
import { ReactComponent as Completed } from "../../../Images/complete.svg";
import { ReactComponent as Reject } from "../../../Images/reject.svg";
import LenderReqeusts from "../../../../utils/services/lenderRequests";
import { toast } from "react-toastify";

function AllLoans({ data }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingItems, setLoadingItems] = useState({});
  const totalPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    const filtered = data.filter((item) =>
      [item?.refNo, item?.status].some((field) =>
        field.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const generateReciept = async (item) => {
    setLoadingItems(prev => ({
      ...prev,
      [item.id]: true
    }));


    await LenderReqeusts.getLendingCertificate(item.id)
      .then((res) => {
        console.log("is respone", res?.result);
        // setLoading(false);

        if (res.data.statusCode === 200 && res.data.result) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          downloadPDF(res.data.result);
        } else {
          toast.error(res?.data?.message || "an error occured", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setLoadingItems(prev => ({
          ...prev,
          [item.id]: false
        }));
      });
  };

  return (
    <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
      <div className="flex h-fit justify-between items-center w-full lg:p-[16px]">
        {search ? (
          <div
            onClick={() => setSearch(false)}
            className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
          >
            <Search />
            <input
              className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
              placeholder="Search"
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setSearch(true);
            }}
            className="px-3.5 py-2.5 flex md:hidden"
          >
            <Search />
          </div>
        )}

        <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
          <Search />
          <input
            className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
        {[
          "S/N",
          "ID",
          "Loan Amount",
          "Lending Date",
          "Maturity Date",
          "Status",
          "Accrued Interest Amount",
          // "Accrued Interest (R & A)"
          "",
        ]?.map((header) => (
          <div
            key={header}
            className="w-full flex flex-col justify-start items-start"
          >
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">
              {header}
            </div>
            {currentData?.map((item, index) => (
              <div
                key={item?.id}
                className={`h-[72px] w-full px-6 py-4 ${
                  index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"
                } justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}
              >
                {header === "S/N" && startIndex + index + 1}
                {header === "ID" && <div>{item?.refNo}</div>}
                {header === "Lending Date" &&
                  formatDate(new Date(item?.createdDate))}
                {header === "Maturity Date" &&
                  formatDate(new Date(item?.maturityDate))}

                {header === "Accrued Interest Amount" && (
                  <div>{CurrencyFormatter(item?.accuredAmount || 0, "$")}</div>
                )}

                {header === "Loan Amount" && (
                  <div>{CurrencyFormatter(item?.amount || 0, "$")}</div>
                )}

                {header === "Status" && (
                  <div
                    className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${
                      item?.status === "ACTIVE"
                        ? "bg-[#f1fcf2]"
                        : item?.status === "INACTIVE"
                        ? "bg-red-100"
                        : item?.status === "LIQUIDATED"
                        ? "bg-gray-50"
                        : "bg-[#ffffea]"
                    }`}
                  >
                    {/* {item?.status === "ACTIVE" ? (
                          <Completed />
                        ) : item?.status === "INACTIVE" ? (
                          <Reject />
                        ) : (
                          <Pending />
                        )} */}
                    <div
                      className={`text-center ${
                        item?.status === "ACTIVE"
                          ? "text-[#1f7634]"
                          : item?.status === "INACTIVE"
                          ? "text-red-500"
                          : item?.status === "LIQUIDATED"
                          ? "text-[#4A4F54]"
                          : "text-[#bb6902]"
                      } text-xs font-medium leading-[18px]`}
                    >
                      {item?.status.charAt(0).toUpperCase() +
                        item?.status.slice(1).toLowerCase()}
                    </div>
                  </div>
                )}

                {header === "Accrued Interest (R & A)" && (
                  <div>{item?.accuredInterest || 0}%</div>
                )}

                {header === "" && (
                  <button
                    className=" text-[#0c4aff]"
                    onClick={() => generateReciept(item)}
                  >
                   {loadingItems[item.id] ? "Generating..." : "Generate Certificate"}
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* <Footer /> */}

      <Paginator
        total={data.length || 0}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default AllLoans;
