import WhatsApp from "../Images/whatsapp.svg";
import mail from "../Images/mail-02.svg";
import call from "../Images/call.svg";
import triangle from "../Images/triangleHelp.svg";
import cancel from "../Images/Cancel.svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
const contact = [
  {
    id: 1,
    name: "Open WhatsApp",
    icon: WhatsApp,
    type: "whatsapp",
    contact: "+2348163153270",
  },
  {
    id: 2,
    name: "Send an Email",
    icon: mail,
    type: "email",
    contact: "Hello@ijefund.com",
  },
  {
    id: 3,
    name: "Call Us",
    icon: call,
    type: "tel",
    contact: "+2348163153270",
  },
];

const NeedHelp = () => {
  const [help, setHelp] = useState(false);
  const [navBar, setNavBar] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className={` rounded-[50px] relative   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-full `}>
        <div
          onClick={() => {
            // window.scrollTo(0, 0);
            navigate(-1);
          }}
          // to={"/lender/dashboard"}
        >
          <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
            <Back />
            <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
          </div>
        </div>
        <div>
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to="/"
          >
            <img className="" src={logo} alt="Ije" />
          </Link>
        </div>
        <button onClick={() => setHelp(!help)} className={`px-1 ${help ? "  bg-[#e8f7ff] rounded-[99px]" : ""} text-[#565c64] text-base font-medium font-inter leading-normal`}>
          Need Help?
        </button>
        {help && (
          <div className=" absolute  100:top-[60px] lg:top-[80px] right-5  justify-end items-end  flex flex-col 100:w-[90%] phone:w-[416px] h-fit">
            <img className="w-[70px] mb-[-30px] h-[70px]" src={triangle} alt="" />
            <div className="h-fit w-full px-6 py-5 bg-[#0a205c] rounded-xl shadow flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-full justify-end items-center flex">
                <div onClick={() => setHelp(false)} className="w-[36px] cursor-pointer h-[36px] bg-[#103a9f] rounded-[100px] flex-col justify-center items-center flex">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>
              <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                <div className="w-full flex-col justify-center items-center gap-2 flex">
                  <h2 className="self-stretch text-center text-[#f5f6f6] text-lg font-medium font-inter leading-7">How can we assist you?</h2>
                  <p className="self-stretch text-center text-[#aab0b6] text-sm font-normal font-inter leading-tight">
                    Choose how you'd like to reach us—via WhatsApp, email, or a direct call. <span className="text-[#e5e7e8] text-sm font-medium ">We're here to help!</span>
                  </p>
                </div>
                <div className="w-full py-2.5 100:justify-center sm:justify-start 100:flex-wrap sm:flex-nowrap items-start gap-3 inline-flex">
                  {contact.map((item) => (
                    <>
                      {item.type === "email" ? (
                        <a href={`mailto:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                          <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                          <img src={item.icon} alt={item.name} className="h-5 w-5" />
                        </a>
                      ) : item.type === "tel" ? (
                        <a href={`tel:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                          <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                          <img src={item.icon} alt={item.name} className="h-5 w-5" />
                        </a>
                      ) : item.type === "whatsapp" ? (
                        <a
                          href={`https://wa.me/${item.contact}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={item.id}
                          className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5"
                        >
                          <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                          <img src={item.icon} alt={item.name} className="h-5 w-5" />
                        </a>
                      ) : (
                        <div key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                          <h3 className="ttext-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                          <img src={item.icon} alt={item.name} className="h-5 w-5" />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NeedHelp;
