import { Link } from "react-router-dom";
import hero from "../Images/lenderHero.png";
function Lender() {
  return (
    <>
      <div style={{ backgroundImage: ` url(${hero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className=" w-full 100:h-[900px] lg:h-[1004px]  justify-end 100:py-[108px] lg:py-[128px]  items-center flex flex-col">
        {/* <img className="w-full " src={hero} alt="Hero" /> */}
        <div className=" 100:w-[85.7%] 1xl:w-[1250px] lg:px-[32px] xl:px-[68px]   flex flex-col gap-6  ">
          <div className="w-full 100:flex lg:hidden flex-col gap-[24px]">
            <div className=" gap-3 flex flex-col justify-start items-start w-full">
              <h1 className="text-neutral-100 100:text-4xl md:text-6xl  font-bold  uppercase">Lend with Impact</h1>
              <h1 className="text-neutral-100 100:text-4xl md:text-6xl  font-bold  uppercase">Earn with Confidence</h1>
            </div>
            <div className="w-full flex flex-col justify-center  gap-6">
              <p className=" text-neutral-300 text-[20px] font-normal  leading-9">
                Support verified students in achieving their study-abroad dreams while earning 12% annual returns. We handle everything—<span className="text-gray-200  font-semibold">you lend, we manage, students succeed</span>.
              </p>
              <Link onClick={() => window.scrollTo(0, 0)} className=" w-fit" to="/auth/lender">
                <button className="h-12  w-fit px-5 py-3 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-gray-400 justify-center items-center gap-2 inline-flex text-neutral-800 text-base font-medium  leading-normal">
                  Start Lending to Students Today
                </button>
              </Link>
            </div>
          </div>
          <div className=" w-full 100:hidden lg:flex  gap-3  justify-stretch flex-col ">
            <h1 className="text-neutral-100 text-6xl justify-start flex font-bold  uppercase">Lend with Impact</h1>
            <div className=" flex  justify-start flex-col gap-6  w-full items-end ">
              <h1 className="text-neutral-100 text-6xl  font-bold  uppercase">Earn with Confidence</h1>
              <div className="w-[750px] flex flex-col justify-center  gap-6">
                <p className=" text-neutral-300 text-2xl font-normal  leading-9">
                  Support verified students in achieving their study-abroad dreams while earning 12% annual returns. We handle everything—<span className="text-gray-200 text-2xl font-semibold">you lend, we manage, students succeed</span>.
                </p>
                <Link onClick={() => window.scrollTo(0, 0)} className=" w-fit" to="/auth/lender">
                  <button className="h-12  hover:border-2  w-fit px-5 py-3 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-gray-400 justify-center items-center gap-2 inline-flex text-neutral-800 text-base font-medium  leading-normal">
                    Start Lending to Students Today
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lender;
