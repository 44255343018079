import { Link } from "react-router-dom";

function WhyLenders() {
  const boxes = [
    {
      id: 1,
      icon: (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44.0855 20.8445C46.2354 20.0384 46.4291 16.1871 44.518 12.2423C42.607 8.29753 39.315 5.75312 37.1651 6.55919M44.0855 20.8445C41.9356 21.6505 38.6436 19.1061 36.7326 15.1613C34.8216 11.2166 35.0152 7.36526 37.1651 6.55919M44.0855 20.8445L12.8355 39.5945C10.6856 40.4005 7.39362 37.8561 5.48261 33.9113C3.5716 29.9666 3.76525 26.1153 5.91513 25.3092L37.1651 6.55919"
            stroke="#AAB0B6"
            stroke-width="2"
          />
          <path d="M31.25 28.5075C28.413 27.3138 22.7569 22.9735 22.8279 15.1616M23.9583 32.87C21.5278 31.7101 16.4583 27.4065 15.625 19.471" stroke="#AAB0B6" stroke-width="2" />
          <path d="M32.1462 29.3267C33.3911 31.6812 34.6649 37.8941 30.3123 43.91L28.257 40.9599L22.917 43.5148C22.917 43.5148 26.7188 39.5076 24.9019 33.4933" stroke="#AAB0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      title: "Empower Education",
      description: "Your funds help students achieve their dream of studying abroad.",
    },
    {
      id: 2,
      icon: (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M43.6307 35.2336C42.2633 27.0069 38.0066 20.8809 34.3062 17.2828C33.2294 16.2357 32.691 15.7122 31.5016 15.2277C30.3123 14.7432 29.29 14.7432 27.2454 14.7432H22.7546C20.71 14.7432 19.6877 14.7432 18.4984 15.2277C17.309 15.7122 16.7706 16.2357 15.6938 17.2828C11.9934 20.8809 7.73668 27.0069 6.36931 35.2336C5.35194 41.3545 10.9985 45.9932 17.309 45.9932H32.691C39.0015 45.9932 44.6481 41.3545 43.6307 35.2336Z"
            stroke="#AAB0B6"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M15.1176 9.41597C14.6878 8.79037 14.0648 7.94123 15.3517 7.74759C16.6745 7.54855 18.048 8.45404 19.3925 8.43543C20.6088 8.41859 21.2284 7.87914 21.8932 7.10891C22.5933 6.29786 23.6772 4.32666 24.9997 4.32666C26.3221 4.32666 27.4061 6.29786 28.1061 7.10891C28.7709 7.87914 29.3906 8.41859 30.6069 8.43543C31.9513 8.45404 33.3248 7.54855 34.6476 7.74759C35.9345 7.94123 35.3115 8.79037 34.8817 9.41597L32.9383 12.2447C32.107 13.4547 31.6913 14.0597 30.8214 14.4015C29.9516 14.7433 28.8275 14.7433 26.5793 14.7433H23.4201C21.1719 14.7433 20.0478 14.7433 19.1779 14.4015C18.308 14.0597 17.8924 13.4547 17.061 12.2447L15.1176 9.41597Z"
            stroke="#AAB0B6"
            stroke-width="2"
          />
        </svg>
      ),
      title: "Hassle-Free Lending",
      description: "We take care of all the details, so you can lend with peace of mind.",
    },
    {
      id: 3,
      icon: (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M43.2793 37.6602C44.8403 37.6602 46.0819 36.6778 47.1968 35.3042C49.479 32.4923 45.7319 30.2452 44.3028 29.1447C42.8501 28.026 41.228 27.3923 39.5833 27.2435M37.5 23.0768C40.3765 23.0768 42.7083 20.745 42.7083 17.8685C42.7083 14.992 40.3765 12.6602 37.5 12.6602"
            stroke="#AAB0B6"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M6.72042 37.6602C5.15937 37.6602 3.91773 36.6778 2.80289 35.3042C0.520692 32.4923 4.26774 30.2452 5.69685 29.1447C7.14962 28.026 8.77172 27.3923 10.4163 27.2435M11.458 23.0768C8.58152 23.0768 6.24967 20.745 6.24967 17.8685C6.24967 14.992 8.58152 12.6602 11.458 12.6602"
            stroke="#AAB0B6"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M16.8409 31.6421C14.7122 32.9584 9.13089 35.6461 12.5303 39.0093C14.1909 40.6522 16.0403 41.8272 18.3656 41.8272H31.6338C33.959 41.8272 35.8085 40.6522 37.469 39.0093C40.8685 35.6461 35.2871 32.9584 33.1584 31.6421C28.1666 28.5555 21.8327 28.5555 16.8409 31.6421Z"
            stroke="#AAB0B6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32.2913 15.7853C32.2913 19.8124 29.0268 23.077 24.9997 23.077C20.9726 23.077 17.708 19.8124 17.708 15.7853C17.708 11.7582 20.9726 8.49365 24.9997 8.49365C29.0268 8.49365 32.2913 11.7582 32.2913 15.7853Z"
            stroke="#AAB0B6"
            stroke-width="2"
          />
        </svg>
      ),
      title: "a Purpose-Driven Community",
      description: "Be part of a network of lenders who believe in the power of education.",
    },
  ];
  return (
    <>
      <div className="w-full h-fit 100:p-8 md:p-16 bg-teal-950 flex-col justify-start items-start gap-16 inline-flex ">
        <div className="w-full flex-col justify-center items-center gap-2.5 inline-flex">
          <h2 className="text-neutral-100 100:text-4xl md:text-4xl font-bold capitalize">why Lend with Ije?</h2>
        </div>
        <div className="w-full  flex-wrap justify-center items-start gap-8 inline-flex ">
          {boxes.map((item) => (
            <div
              key={item.id}
              className={`h-fit transition-all duration-300 hover:shadow-lg hover:shadow-teal-700/40 hover:scale-105 hover:brightness-110  bg-teal-900 flex-1 min-w-[316px] 2xl:max-w-[416px] p-6 rounded-lg flex-col justify-start items-start gap-6 inline-flex `}
            >
              <span>{item.icon}</span>
              <div className="w-full text-left flex-col justify-start items-start gap-1.5 inline-flex ">
                <h3 className=" text-gray-200 text-2xl font-semibold  capitalize leading-9">{item.title}</h3>
                <p className="self-stretch text-neutral-300 text-base font-medium  capitalize leading-normal">{item.description}</p>
              </div>
            </div>
          ))}
        </div>{" "}
        <div className="100:w-full 3xl:w-[1550px] justify-end items-center gap-2.5 inline-flex">
          <Link onClick={() => window.scrollTo(0, 0)} className="w-fit" to="/auth/lender">
            <div className="text-neutral-100 text-xl hover:underline font-medium  capitalize leading-7 items-center gap-2.5 justify-center flex-row inline-flex w-fit">
              Start Lending Today
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 12.6602L4 12.6602" stroke="#E5E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 17.6602C15 17.6602 20 13.9777 20 12.6601C20 11.3425 15 7.66016 15 7.66016" stroke="#E5E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default WhyLenders;
