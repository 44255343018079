export const maskEmail = (email) => {
  const [user, domain] = email.split("@");
  const maskedUser = user.length > 3 ? `${user.substring(0, 3)}********` : `${user}********`;
  return `${maskedUser}@${domain}`;
};

export const CurrencyFormatter = (amount, currencySymbol, decimalSeparator = ".", thousandsSeparator = ",") => {
  // Convert amount to string with 2 decimal places
  const formattedAmount = amount.toFixed(2);
  // Split the formatted amount into parts before and after the decimal separator
  const [integerPart, decimalPart] = formattedAmount.split(".");

  // Insert thousands separators
  const integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  // Construct the final formatted currency string
  let result;
  if (currencySymbol) {
    result = currencySymbol + integerWithSeparators + decimalSeparator + decimalPart;
  } else {
    result = "$" + integerWithSeparators + decimalSeparator + decimalPart;
  }
  return result;
};

export const formatDate = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const day = date.getDate();

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const ordinalSuffix = getOrdinalSuffix(day);

  return `${day}${ordinalSuffix} ${date.toLocaleDateString("en-GB", options).split(" ")[1]} ${date.getFullYear()}`;
};

export const formatTime = (date) => {
  // Convert to Nigerian time (WAT/UTC+1)
  const nigerianDate = new Date(date.getTime() + (3600000)); // Add 1 hour in milliseconds

  let hours = nigerianDate.getHours();
  const minutes = nigerianDate.getMinutes().toString().padStart(2, '0');
  const seconds = nigerianDate.getSeconds().toString().padStart(2, '0');
  const period = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set it to 12
  hours = hours.toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds} ${period}`;
};



export const downloadPDF = (pdfData) => {
  // Get the Base64 encoded PDF content
  const pdfContent = pdfData.pdfContent;
  const fileName = pdfData.fileName;
  
  // Convert the Base64 string to a Blob
  const binaryString = window.atob(pdfContent);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const pdfBlob = new Blob([bytes], { type: 'application/pdf' });
  
  // Create an object URL for the Blob
  const blobUrl = URL.createObjectURL(pdfBlob);
  
  // Create a temporary anchor element and trigger download
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  
  // Clean up by removing the link and revoking the Blob URL
  document.body.removeChild(downloadLink);
  setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
};