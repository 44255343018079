import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../../Images/search.svg";
import Footer from "../Footer";
import {
  CurrencyFormatter,
  formatDate,
  formatTime,
} from "../../../utils/helpers/helper";
import { useEffect, useState } from "react";
import AdminRequest from "../../../utils/services/adminRequests";
import Paginator from "../../shared/Paginator";

function OnboarderdLenders() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const getLenders = async () => {
    await AdminRequest.getAllLenderUsers({ search: searchQuery }).then(
      (res) => {
        // console.log("isres", res?.data?.result )
        if (res?.data?.result !== undefined) {
          setData(res?.data?.result || []);
        }
      }
    );
  };

  useEffect(() => {
    getLenders();
  }, []);

  useEffect(() => {
    const filtered = data.filter((item) =>
      [item.firstName, item.lastName, item.email].some((field) =>
        field.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const onClick = (item) => {
    console.log("item", item);

    // /approver/lenders-details"
  };

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
      <div className="flex justify-between items-center w-full p-[20px]">
        {search ? (
          <div
            onClick={() => setSearch(false)}
            className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
          >
            <Search />
            <input
              className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
              placeholder="Search"
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setSearch(true);
            }}
            className="px-3.5 py-2.5 flex md:hidden"
          >
            <Search />
          </div>
        )}
        {/* )} */}
        <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
          <Search />
          <input
            className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
        {[
          "S/N",
          "ID",
          "Name",
          "Email",
          "Date Onboarded",
          "Time",
          "Total funds Lent",
          "",
        ]?.map((header) => (
          <div
            key={header}
            className="w-full flex flex-col justify-start items-start"
          >
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">
              {header}
            </div>
            {currentData?.map((item, index) => (
              <div
                key={item?.id}
                className={`h-[72px] w-full px-6 py-4 ${
                  index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"
                } justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}
              >
                {header === "S/N" && startIndex + index + 1}
                {header === "ID" && item?.userid}
                {header === "Name" && (
                  <div className=" text-pretty ">
                    {item?.firstName} {item?.lastName}
                  </div>
                )}
                {header === "Date Onboarded" &&
                  formatDate(new Date(item?.createdAt))}

                {header === "Time" && (
                  <div>{formatTime(new Date(item?.createdAt))}</div>
                )}
                {header === "Email" && (
                  <div className=" text-pretty ">{item?.email} </div>
                )}
                {header === "Total funds Lent" && (
                  <div>{CurrencyFormatter(item?.total_lent || 0, "$")}</div>
                )}

                {header === "" && (
                  <div className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                    <button
                      onClick={() => {
                        navigate(
                          `/approver/lenders-management/lenders-details/${item?.id}`
                        );
                      }}
                    >
                      View
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <Paginator
        total={data.length || 0}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default OnboarderdLenders;
