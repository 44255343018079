import { useState } from "react";

const FilterComp = ({ title, options, onFilter, isFiltered }) => {
  const [isOpen, setOpen] = useState(false);
  const filter = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83337 17.5L5.83337 15" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1667 17.5L14.1667 12.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1667 5L14.1667 2.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.83337 7.5L5.83337 2.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.83337 15C5.0568 15 4.66852 15 4.36224 14.8731C3.95385 14.704 3.6294 14.3795 3.46024 13.9711C3.33337 13.6649 3.33337 13.2766 3.33337 12.5C3.33337 11.7234 3.33337 11.3351 3.46024 11.0289C3.6294 10.6205 3.95385 10.296 4.36223 10.1269C4.66852 10 5.0568 10 5.83337 10C6.60994 10 6.99823 10 7.30451 10.1269C7.71289 10.296 8.03735 10.6205 8.20651 11.0289C8.33337 11.3351 8.33337 11.7234 8.33337 12.5C8.33337 13.2766 8.33337 13.6649 8.20651 13.9711C8.03735 14.3795 7.71289 14.704 7.30451 14.8731C6.99823 15 6.60994 15 5.83337 15Z"
        stroke="#6C757D"
        stroke-width="1.5"
      />
      <path
        d="M14.1667 10C13.3902 10 13.0019 10 12.6956 9.87313C12.2872 9.70398 11.9628 9.37952 11.7936 8.97114C11.6667 8.66485 11.6667 8.27657 11.6667 7.5C11.6667 6.72343 11.6667 6.33515 11.7936 6.02886C11.9628 5.62048 12.2872 5.29602 12.6956 5.12687C13.0019 5 13.3902 5 14.1667 5C14.9433 5 15.3316 5 15.6379 5.12687C16.0463 5.29602 16.3707 5.62048 16.5399 6.02886C16.6667 6.33515 16.6667 6.72343 16.6667 7.5C16.6667 8.27657 16.6667 8.66485 16.5399 8.97114C16.3707 9.37952 16.0463 9.70398 15.6379 9.87313C15.3316 10 14.9433 10 14.1667 10Z"
        stroke="#6C757D"
        stroke-width="1.5"
      />
    </svg>
  );
  return (
    <div className=" relative">
      <button
        onClick={() => {
          setOpen(!isOpen);
        }}
        className="h-9 100:w-full md:w-fit 100:px-2 sm:px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] justify-center items-center gap-2 flex text-[#6c757d] 100:text-[12px] md:text-sm font-medium  leading-tight"
      >
        {filter}
        <span className=" 100:hidden md:flex">{title}</span>{" "}
      </button>

      {isOpen && (
        <div className=" absolute w-full top-10 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] bg-white rounded-lg px-2">
          {options.map((item) => (
            <div className=" flex flex-col py-1 gap-2 item-start">
              <button
                className="px-2 p-1.5 h-fit w-full hover:bg-slate-100  text-gray-700 text-sm font-medium text-left"
                onClick={() => {
                  onFilter(item);
                  setOpen(!isOpen);
                }}
              >
                {item}
              </button>
              <div className=" w-full h-px bg-[#d5d8d8]" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterComp;
