import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../Images/Logo.svg";
import { ReactComponent as Lender } from "../Images/options-icon/lender.svg";
import { ReactComponent as Student } from "../Images/options-icon/student.svg";

function Options() {
  const [navBar, setNavBar] = useState(false);
  const [active, setActive] = useState(null);
  const option = [
    {
      id: 1,
      icon: (active) => <Lender className={`100:w-[30px] md:w-[50px] ${active ? "text-[#0C4CFF]" : "text-[#565C64]"}`} />,
      title: "Empower a Dream",
      des: "Help Africans access international education.",
      path: "/auth/lender",
      buttonTitle: "Become a Lender",
    },
    {
      id: 1,
      icon: (active) => <Student className={`100:w-[30px] md:w-[50px] ${active ? "text-[#0C4CFF]" : "text-[#565C64]"}`} />,
      title: "Fund Your Future",
      des: "Get the financial support you need to study abroad.",
      path: "/auth/register",
      buttonTitle: "Apply as a Student",
    },
  ];
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  return (
    <>
      <div className=" bg-white flex-col h-full w-full gap-[100px] pb-[150px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div className={` rounded-[50px]   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-full `}>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/"}
            >
              <h4 className=" hover:text-blue-600 w-fit text-gray-500 text-base font-medium  leading-normal">Home</h4>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className="w-fit justify-center gap-[4px] items-center inline-flex">
              <h3 className="text-gray-500 100:hidden md:flex text-sm font-normal leading-tight">Have an account?</h3>
              <Link to="/auth/login">
                <div className="text-blue-600 hover:underline text-sm font-medium leading-tight">Login</div>
              </Link>
            </div>
          </div>
        </div>
        <div className=" 100:w-[85%] 100:pt-[140px] md:pt-[180px]  gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 100:text-[14px] md:text-base font-semibold  leading-normal">Sign Up</h4>
            <h2 className=" text-center text-neutral-700 100:text-[24px] md:text-3xl 100:leading-6 font-semibold  md:leading-10">Unlock Opportunities</h2>
          </div>
          <div className="md:w-fit 100:w-full flex-wrap justify-start items-start gap-8 inline-flex ">
            {option.map((item, index) => (
              <div
                key={index}
                className={`sm:w-[300px] 100:w-full h-fit  100:p-4 md:p-6 rounded-lg border ${
                  active === index ? "bg-blue-100  border-blue-400" : " bg-transparent border-neutral-300"
                } flex-col justify-start items-start 100:gap-4 md:gap-6 inline-flex `}
                to={item.path}
              >
                <span>{item.icon(active === index)}</span>
                <div className="w-full flex-col text-left justify-start items-start gap-1.5 inline-flex ">
                  <h3 className=" text-neutral-600 100:text-[18px] md:text-xl font-semibold  capitalize leading-7">{item.title}</h3>
                  <p className="self-stretch text-[#6C757D] text-sm font-normal  capitalize leading-tight">{item.des}</p>
                </div>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault(); // Prevent instant navigation
                    setActive(index); // Set active state
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                      window.location.href = item.path; // Redirect after 0.5s delay
                    }, 500);
                  }}
                  className="w-full"
                >
                  <button
                    className={`h-fit w-full hover:border-blue-600 hover:border py-2 ${
                      active === index ? "bg-blue-600 text-white" : "bg-blue-50 text-blue-600"
                    } rounded-lg justify-center items-center gap-2.5 inline-flex  text-sm font-medium capitalize leading-tight
`}
                  >
                    {item.buttonTitle}
                  </button>
                </NavLink>
              </div>
            ))}
          </div>
          <div className=" w-full 100:flex md:hidden justify-center items-start inline-flex">
            <div className="w-fit justify-center gap-[4px] items-center inline-flex">
              <h3 className="text-gray-500 text-sm font-normal leading-tight">Have an account?</h3>
              <Link to="/auth/login">
                <div className="text-blue-600 hover:underline text-sm font-medium leading-tight">Login</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Options;
