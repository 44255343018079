import { useState } from "react";
import cancel from "../../../Images/Cancel.svg";
import {
  CurrencyFormatter,
  formatDate,
  formatTime,
} from "../../../../utils/helpers/helper";
import AdminRequest from "../../../../utils/services/adminRequests";
import { toast } from "react-toastify";
// import confirm from "../../../Images/Confirm.svg";

function WithdrawalRequest({ data }) {
  const [approve, setApprove] = useState(false);
  const [isOption, setOption] = useState("");
  const [isSelected, setSelected] = useState({});
  const [isLoading, setLoading] = useState(false);
  const warning = (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9988 8.50021V12.5002M11.9988 16.5002H12.0088M10.2888 3.3602L1.8188 17.5002C1.64417 17.8026 1.55177 18.1455 1.55079 18.4947C1.54981 18.8439 1.64029 19.1873 1.81323 19.4907C1.98616 19.7941 2.23553 20.047 2.53651 20.2241C2.83749 20.4012 3.1796 20.4964 3.5288 20.5002H20.4688C20.818 20.4964 21.1601 20.4012 21.4611 20.2241C21.7621 20.047 22.0114 19.7941 22.1844 19.4907C22.3573 19.1873 22.4478 18.8439 22.4468 18.4947C22.4458 18.1455 22.3534 17.8026 22.1788 17.5002L13.7088 3.3602C13.5305 3.06631 13.2795 2.82332 12.98 2.65469C12.6805 2.48605 12.3425 2.39746 11.9988 2.39746C11.6551 2.39746 11.3171 2.48605 11.0176 2.65469C10.7181 2.82332 10.4671 3.06631 10.2888 3.3602Z"
        stroke="#BB6902"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  console.log("is da", data);

  const handleSelect = (option, item) => {
    setOption(option);
    // console.log("issels", item)
    setSelected(item);
    setApprove(!approve);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await AdminRequest.postLenderWithdraw(
      isSelected?.id,
      isOption === "approve" ? true : false
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // setApprove(false);

          setTimeout(() => {
            window.location.reload();
          }, 1200); 
        } else {
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setApprove(false);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="w-full justify-start  flex-1 flex 100:flex-wrap items-center  xl:flex-nowrap gap-4 i">
        {data.map((item) => (
          <div
            key={item.id}
            className="flex-grow 100:min-w-[325px] xl:min-w-[525px]  inline-flex self-stretch h-fit p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)]  border border-neutral-300 flex-col justify-start items-start gap-6 "
          >
            <div className="w-full justify-between items-center gap-2.5 inline-flex">
              <h3 className="self-stretch text-neutral-700 text-base font-medium leading-normal">
                Withdrawal Requests
              </h3>
              <div
                className={`mix-blend-multiply w-fit px-2 py-0.5 ${
                  item.status === "PENDING"
                    ? "bg-yellow-50 text-amber-700"
                    : item.status === "SUCCESS"
                    ? " bg-[#F1FCF2] text-[#1F7634]"
                    : "bg-red-100 text-red-800"
                }  rounded-2xl justify-center items-center flex text-center  text-xs font-medium leading-none`}
              >
                {item.status}
              </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Transaction ID
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {item.refNo}
                </h3>
              </div>
              <div className="self-stretch h-px bg-gray-200" />
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Amount Requested
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {/* {item.amountRequested} */}

                  {CurrencyFormatter(item?.amount)}
                </h3>
              </div>
              <div className="self-stretch h-px bg-gray-200" />
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Transaction Type
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {item.type}
                </h3>
              </div>
              {/* <div className="self-stretch h-px bg-gray-200" />
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Liquidated Loan
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {item.liquidatedLoan}
                </h3>
              </div> */}
              <div className="self-stretch h-px bg-gray-200" />
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Reason
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {item?.details}

                </h3>
              </div>
              <div className="self-stretch h-px bg-gray-200" />
              <div className="w-full justify-between items-center inline-flex">
                <h3 className="text-gray-500 text-xs font-normal leading-none">
                  Date of Request
                </h3>
                <h3 className="text-neutral-600 text-sm font-medium leading-tight">
                  {/* {item.dateOfRequest} */}
                  {formatDate(new Date(item?.createdDate))}{" "}
                  {formatTime(new Date(item?.createdDate))}
                </h3>
              </div>
            </div>
            <div className="w-full  border-t border-t-gray-200  justify-end items-center pt-4 inline-flex">
              <button
                onClick={() => handleSelect("reject", item)}
                className="px-3 py-1 rounded justify-center items-center  flex text-neutral-600 text-sm font-medium leading-tight"
              >
                Reject
              </button>
              <button
                onClick={() => handleSelect("approve", item)}
                className={`px-3 py-1 rounded justify-center ${
                  approve && " disabled:text-opacity-15"
                } items-center bg-blue-50 flex text-blue-600 text-sm font-medium leading-tight`}
              >
                Approve
              </button>
            </div>
          </div>
        ))}
      </div>
      {approve && (
        <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div
              onClick={() => {
                setApprove(false);
              }}
              className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
            >
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
            <div className=" 100:w-full iphone:w-96 h-fit  p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
              <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                <div className="w-full flex-col justify-start items-center gap-5 flex">
                  <div className="w-12 h-12   justify-center flex  items-center  bg-[#FFFBC5] rounded-3xl border-8 border-[#FFFFEA]  ">
                    {warning}
                  </div>
                  <div className="w-full flex-col justify-start items-center gap-2 flex">
                    <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                      Confirmation
                    </h3>
                    <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                      Are you sure you want to {isOption} this withdrawal?
                    </p>
                  </div>
                </div>
                <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="leading-[20px]  border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                  >
                    {isLoading ? "Loading..." : "Proceed"}
                  </button>
                  <button
                    onClick={() => {
                      setApprove(false);
                    }}
                    className="h-12 w-full rounded-lg border border-gray-400 jgrow shrink basis-0 px-5 py-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]  justify-center items-center gap-2 flex text-neutral-600 text-base font-medium  leading-normal"
                  >
                    Cancel
                  </button>
                </div>{" "}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default WithdrawalRequest;
