import { useFormik } from "formik";
import LenderReqeusts from "../../../utils/services/lenderRequests";
import { lenderCalculatorSimp } from "../../../utils/services/validation";
import { useState } from "react";
import TextInput from "../../shared/input/TextInput";
import Select from "react-select";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import { mothsofInvestment } from "../../../utils/helpers/constants";
import c from "../../Images/calculator.png";
import cancel from "../../Images/Cancel.svg";

const LoanCalculator = ({ closeCal }) => {
  const [loanCal, setLoanCal] = useState({});
  const [active, setActive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: lenderCalculatorSimp,
    initialValues: {
      amount: "",
      time: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await LenderReqeusts.calculateLendSimp(
        parseFloat(values.amount),
        parseFloat(values.time)
      )
        .then((res) => {
          console.log("is restt", res);
          setLoading(false);
          if (res.data.result) {
            setActive(true);
            setLoanCal(res.data.result);
          }
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <div className="w-full   h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
      <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
        <div
          onClick={closeCal}
          className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
        >
          <img className=" " src={cancel} alt="Cancel" />
        </div>
      </section>
      <section className=" w-full h-screen items-center flex justify-center">
        <div className="phone:w-[400px]  100:w-[85%] overflow-y-scroll   h-[85%] p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] flex-col justify-start items-center gap-8 ">
          <div className="w-full flex-col justify-start items-start gap-5 inline-flex">
            <img src={c} alt="" />
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
              <h3 className="self-stretch text-[#242628] text-lg font-medium leading-7">
                Simulate Your Returns
              </h3>
              <p className="self-stretch text-[#6c757d] text-sm font-normal leading-tight">
                Use the calculator below to estimate your payout based on your
                Lending amount and duration.
              </p>
            </div>
            <div className="flex flex-col gap-4 w-full items-start justify-center ">
              <TextInput
                label={"Lending Amount (min. $100)"}
                type="text"
                placeholder="Enter the Amount to Lend "
                value={formik.values.amount}
                error={formik.touched.amount && formik.errors.amount}
                onChange={formik.handleChange("amount")}
              />
              <label
                className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                htmlFor="time"
              >
                Investment Duration (months)
                <Select
                  className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "100%",
                      height: "48px",
                      background: "#fff",
                      outline: "none",
                    }),
                  }}
                  placeholder="Select duration"
                  name="duration"
                  options={mothsofInvestment}
                  onChange={(e) => {
                    formik.setFieldValue("time", e.value);
                  }}
                />
              </label>{" "}
              {active && (
                <div className="h-fit w-full p-4 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex overflow-hidden">
                  <div className="text-[#6c757d] text-sm font-normal fleading-tight">
                    If you lend{" "}
                    <span class="text-[#242628] text-sm font-medium leading-tight">
                      {CurrencyFormatter(loanCal?.principal, "$")}
                    </span>{" "}
                    for{" "}
                    <span class="text-[#242628] text-sm font-medium leading-tight">
                      {loanCal?.duration || 0} months
                    </span>
                    ,
                    <span class="text-[#242628] text-sm font-semibold leading-tight">
                      {CurrencyFormatter(loanCal?.maturityAmount, "$")}
                    </span>{" "}
                    will be your toatal payout plus interest.{" "}
                  </div>
                  <div className="h-fit w-full p-3 bg-white rounded-lg justify-between items-center inline-flex">
                    {/* <PieChart
                              className=" flex  "
                              width={100}
                              height={100}
                            >
                              <Pie
                                data={data}
                                cx={80}
                                cy={80}
                                innerRadius={80}
                                outerRadius={80}
                                fill="#8884d8"
                                paddingAngle={50}
                                dataKey="value"
                              >
                                {data.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Pie
                                data={data}
                                cx={80}
                                cy={20}
                                startAngle={20}
                                endAngle={0}
                                innerRadius={20}
                                outerRadius={20}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                              >
                                {data.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                            </PieChart> */}
                    <div className=" flex flex-col justify-start items-start gap-[4px] w-full">
                      <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                        {" "}
                        <div className="w-2 h-2 bg-[#B3DFFF] rounded-full"></div>
                        Principal Amount:
                        <span className="text-[#242628] font-medium ">
                          {" "}
                          {CurrencyFormatter(loanCal?.principal, "$")}
                        </span>
                      </div>

                      <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                        {" "}
                        <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                        Interest Rate:
                        <span className="text-[#242628] font-medium ">
                          {loanCal?.rate || 0}%
                        </span>
                      </div>
                      <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                        {" "}
                        <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                        Duration:
                        <span className="text-[#242628] font-medium ">
                          {loanCal?.duration || 0} months
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full justify-start items-start gap-3 inline-flex">
              <button
                onClick={closeCal}
                className=" w-full h-10  justify-center items-center flex  px-[18px] py-2.5 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#cfd4dc]  gap-2  text-[#4a4f54] text-sm font-medium  leading-normal"
              >
                Cancel
              </button>
              {/* <Link
                        className="w-full"
                        to={active ? "/lender/lend" : null}
                      > */}
              <button
                onClick={formik.handleSubmit}
                className={`w-full h-10  justify-center items-center flex  px-[18px] py-2.5  gap-2    ${" bg-[#0c4cff]"} rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d5efff] text-white text-sm font-medium  leading-tight`}
              >
                {isLoading ? "Loading..." : "Calculate"}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoanCalculator;
