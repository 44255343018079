import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import Deposit from "../LoanComponents/deposit";
import c from "../../Images/calculator.png";
import TextInput from "../../shared/input/TextInput";
import Select from "react-select";
import { PieChart, Pie, Cell } from "recharts";
import Withdraw from "../LoanComponents/withdraw";
import cancel from "../../Images/Cancel.svg";
import InvestmentList from "../LoanComponents/investmentList";
import { mothsofInvestment } from "../../../utils/helpers/constants";
import { useFormik } from "formik";
import { lenderCalculatorSimp } from "../../../utils/services/validation";
import LenderReqeusts from "../../../utils/services/lenderRequests";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import FilterComp from "../../shared/input/FilterComp";
import LoanCalculator from "../LoanComponents/LoanCalculator";
function Loan() {
  const [isData, setData] = useState({});
  const [options, setOptions] = useState("deposit");
  const [calculate, setCalculate] = useState(false);
  const [active, setActive] = useState(false);
  const [loanCal, setLoanCal] = useState({});
  const [isDeposits, setDeposits] = useState([]);
  const [isWithdrawals, setWithdrawals] = useState([]);
  const [isInvestments, setInvestments] = useState([]);
  const [isFilterd, setFiltered] = useState("DEPOSIT");

  const getTransactions = async () => {
    await LenderReqeusts.getLenderTransactions().then((res) => {
      const transactions = res?.data?.result?.transactions || [];

      const deposits = transactions?.filter(
        (transaction) => transaction?.type === "DEPOSIT"
      );
      const withdrawals = transactions?.filter(
        (transaction) => transaction?.type === "WITHDRAWAL"
      );

      setDeposits(deposits);
      setWithdrawals(withdrawals);
    });
  };

  const getInvestments = async () => {
    await LenderReqeusts.getLenderInvestments().then((res) => {
      const transactions = res?.data?.result?.investments || [];

      setInvestments(transactions || []);
    });
  };

  useEffect(() => {
    getTransactions();
    getInvestments();
  }, []);

  const formik = useFormik({
    validationSchema: lenderCalculatorSimp,
    initialValues: {
      amount: "",
      time: "",
    },
    onSubmit: async (values) => {
      await LenderReqeusts.calculateLendSimp(
        parseFloat(values.amount),
        parseFloat(values.time)
      ).then((res) => {
        console.log("is restt", res);
        if (res.data.result) {
          setActive(true);
          setLoanCal(res.data.result);
        }
      });
    },
  });

  const calc = (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58333 2.77246L4.58333 6.93913M6.66667 4.85579L2.5 4.85579"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66732 13.6055L5.00065 15.2721M5.00065 15.2721L3.33398 16.9388M5.00065 15.2721L6.66732 16.9388M5.00065 15.2721L3.33398 13.6055"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.667 5.27246L13.3337 5.27246"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6673 15.689L13.334 15.689M16.6673 13.189L13.334 13.189"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.334 10.2725L1.66732 10.2725"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 18.6055L10 1.9388"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const handleToggleOptions = (menu) => {
    setOptions(menu);
  };

  const actionFunc = (data) => {
    console.log("is action", data);
    // setModal(true)
  };

  const filterFunc = (data) => {
    console.log("data", data);
    setFiltered(data);
  };
  return (
    <>
      {" "}
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[90px] md:pt-[120px] lg:pt-[40px] 100:pb-[150px] md:pb-[150px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full 100:flex-col phone:flex-row 100:justify-start 100:gap-[24px] 100:items-stretch  phone:justify-between phone:items-center  inline-flex">
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
              <h3 className="text-[#3a3c3f]  100:text-[20px] md:text-2xl font-medium leading-[30px]">
                Loan
              </h3>
              <p className="self-stretch text-[#6c757d] 100:text-sm lg:text-base font-normal  leading-normal">
                Track, manage, and grow your loan effortlessly
              </p>
            </div>
            <Link className=" 100:w-full phone:w-fit" to="/lender/lend">
              <button className="h-10 text-nowrap w-full text-white text-sm font-medium flex-grow leading-tight px-4 py-2.5 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex">
                Lend Now
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99996 4.16663V15.8333M4.16663 9.99996H15.8333"
                    stroke="white"
                    stroke-width="1.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Link>
          </div>
          <div className=" w-full flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-full h-fit flex-col  justify-start items-start gap-6 inline-flex">
              <div className="w-full 100:gap-4 lg:gap-0 100:flex-col-reverse md:flex-row flex  md:justify-between items-end border-b-[#cdd1d4] border-b-[1px]">
                <div className="lg:w-fit 100:w-full 100:overflow-x-scroll lg:overflow-hidden  whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                  <button
                    className={`h-[38px] w-fit px-[4px] pb-[8px] ${
                      options === "deposit"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleToggleOptions("deposit")}
                  >
                    Deposits
                  </button>
                  {/* <button className={`h-[38px] px-[4px] pb-[8px] ${options === "withdrawals" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleOptions("withdrawals")}>
                    Withdrawals
                  </button> */}
                  <button
                    className={`h-[38px] w-fit px-[4px] pb-[8px] ${
                      options === "investment-list"
                        ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                        : ""
                    }`}
                    onClick={() => handleToggleOptions("investment-list")}
                  >
                    Lending Portfolio
                  </button>
                </div>
                <div className=" text-nowrap md:w-fit md:pb-2 100:w-full justify-start items-start gap-4 inline-flex">
                  <FilterComp
                    title={"Filter by status"}
                    options={["DEPOSIT", "WITHDRAWAL"]}
                    onFilter={filterFunc}
                    isFiltered={isFilterd}
                  />
                  <button
                    onClick={() => setCalculate(!calculate)}
                    className="h-10 100:w-full md:w-fit  100:px-2 sm:px-3 py-2 bg-[#d5efff] rounded-lg border border-[#aab0b6] justify-center items-center gap-2 inline-flex text-[#4a4f54] 100:text-[12px] md:text-sm font-medium leading-tight"
                  >
                    <span className=" 100:hidden mobile:flex">
                      {calculate ? "Cancel" : "Lending Calculator"}
                    </span>{" "}
                    {calculate ? "" : calc}
                  </button>
                </div>
              </div>
              <div className="flex w-full justify-between items-start gap-6">
                <div className="w-full">
                  {options === "deposit" && (
                    <>
                      {isFilterd === "DEPOSIT" ? (
                        <Deposit data={isDeposits} />
                      ) : (
                        // <Withdraw data={isWithdrawals} />
                        <>
                           <Deposit data={isWithdrawals} />
                        </>
                      )}
                    </>
                  )}
                  {/* {options === "withdraw" && <Withdraw data={isWithdrawals} />} */}
                  {options === "investment-list" && (
                    <InvestmentList data={isInvestments} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {calculate && <LoanCalculator closeCal={() => setCalculate(false)} />}
    </>
  );
}

export default Loan;
