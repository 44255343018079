import { useEffect, useRef, useState } from "react";
import iconClose from "../Images/IconCloseWrap.svg";
import iconOpen from "../Images/IconOPenWrap.svg";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import WhatsApp from "../Images/whatsapp.svg";
import mail from "../Images/mail-02.svg";
import call from "../Images/call.svg";
import cancel from "../Images/Cancel.svg";
import triangle from "../Images/triangleHelp.svg";

const contactUs = [
  {
    id: 1,
    name: "Open WhatsApp",
    icon: WhatsApp,
    type: "whatsapp",
    contact: "+2348163153270",
  },
  {
    id: 2,
    name: "Send an Email",
    icon: mail,
    type: "email",
    contact: "hello@ijefund.com",
  },
  {
    id: 3,
    name: "Call Us",
    icon: call,
    type: "tel",
    contact: "+2348163153270",
  },
];
function FAQ() {
  const [open, setOpen] = useState(null);
  const contact = useRef(null);
  const [help, setHelp] = useState(false);
  const home = useRef(null);
  const howIjeWorks = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  const handleOpenToggle = (questionId) => {
    setOpen(open === questionId ? null : questionId); // Toggle between open and close
  };
  useEffect(() => {
    const handleScroll = () => {
      setHelp(false); // Close help on scroll
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header scrollToSection={scrollToSection} home={home} contact={contact} howIjeWorks={howIjeWorks} />
      <div className=" h-fit  mt-[48px] w-full py-[96px] flex justify-center items-center">
        <div className=" w-full flex flex-col justify-start bg-[#f5f6f6] items-center gap-[64px] py-[96px] 100:px-[48px] md:px-[64px] ">
          <h2 className="text-center text-[#3a3c3f] text-4xl font-semibold  leading-[44px]">Frequently asked questions</h2>
          <div className="lg:w-[768px] 100:w-full h-fit flex-col justify-start items-start gap-8 inline-flex">
            <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
              <h3 className="text-blue-600 text-xl font-semibold leading-7">Students</h3>
              <div className="w-full h-px bg-[#cdd1d4]" />
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className=" w-full justify-between items-center text flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What is Ije?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question1")} src={open === "question1" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question1" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Ije is a web-based co-financing platform designed to help African students raise funds for postgraduate studies abroad. We provide a combination of community-driven donations and flexible loans solution to cover the costs of tuition
                  and living expenses of our users.
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How does Ije work?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question2")} src={open === "question2" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question2" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Ije allows prospective students to create personalized fundraising campaigns. You can share your campaign with family, friends, and a broader community to receive donations. If the funds raised through donations are not enough, Ije
                  provides loans to cover the remaining amount, payable over a period of up to 5 years. Students have the option to make their campaign private or public. By private, students can only share the link for donation to their network. And
                  by public, Ije will publish the campaign on its website and share on its social media platforms.
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Who is eligible to use Ije?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question3")} src={open === "question3" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question3" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  For now, we are serving Nigerians who have been accepted into a recognized postgraduate program abroad (starting with the UK). Applicants are accessed and approved to create a fundraising campaign on Ije. We will expand to support
                  other African students targeting UK, US, Canada and Europe.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What can I raise money for on Ije?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question4")} src={open === "question4" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question4" && (
                <div className=" flex flex-col gap-6 w-full text-[#6c757d] text-base font-normal leading-normal">
                  <p className=" w-full">You can raise funds to cover 100% of your financing needs related to your education abroad, including:</p>
                  <ul className="w-full flex flex-col list-disc gap-[10px]">
                    <li>Tuition fees</li>
                    <li>Accommodation and living expenses</li>
                  </ul>
                  <p className=" w-full">
                    You are also required to contribute at least 10% of your total funding requirements towards your education. Ije will also access and ensure your funding request is in line with the destination country’s funding requirement. You
                    cannot request for amount below the legal requirement for both tuition and living expenses.
                  </p>
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do the loans from Ije work?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question5")} src={open === "question5" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question5" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  If donations are not enough to cover your full expenses, Ije offers loans with flexible repayment terms. You can repay the loan over a period of up to 5 years, with a moratorium of up to a year. The loans are designed to ensure you
                  have financial peace of mind while studying abroad.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do I create a campaign on Ije and apply for a loan?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question6")} src={open === "question6" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question6" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  <ul className="w-full flex flex-col list-decimal gap-[10px]">
                    <li>Sign up on Ije.com and create a profile.</li>
                    <li>Submit all the required documents and get approved.</li>
                    <li>Personalize your campaign with your story, photo, and video.</li>
                    <li>Share your campaign with family, friends, and the broader Ije community (opitional) through social media and email</li>
                    <li>Start receiving donations.</li>
                    <li>If you don’t hit the campaign target, you are automatically qualified for a loan to compliment the fund raised through donations</li>
                    <li>The tuition is directly disbursed to the school. We will disburse 30% of the living expenses once the visa has been granted. The balance is remitted after the student settles in the new country.</li>
                  </ul>{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Are there any fees for using Ije?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question7")} src={open === "question7" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question7" && (
                <div className=" flex flex-col gap-6 w-full text-[#6c757d] text-base font-normal leading-normal">
                  <p className=" w-full"> Yes, we charge the following fees and interest</p>
                  <ul className="w-full flex flex-col list-decimal gap-[10px]">
                    <li>2% origination fee on the total amount raised on the platform</li>
                    <li>1% annual maintenance fee charged yearly on outstanding loan amount</li>
                    <li>24% per annum interest rate on the outstanding loan amount</li>
                    <li>Users are responsible for fees associated with funds transfers to school and remittance to self after relocation</li>
                  </ul>{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Can I withdraw money raised on my campaign directly?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question8")} src={open === "question8" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question8" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Donations and loans are intended for educational purposes. Therefore, funds are disbursed directly to your educational institution or in phases to cover your accommodation, living, and other necessary expenses.
                </div>
              )}
            </div>
            {/* LENDERS */}
            <div className="w-full flex-col justify-start mt-8 items-start gap-2.5 inline-flex">
              <h3 className="text-blue-600 text-xl font-semibold leading-7">Lenders</h3>
              <div className="w-full h-px bg-[#cdd1d4]" />
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              {/* <div className="w-full h-px bg-[#cdd1d4]" /> */}
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What is Ije, and how does the lending platform work?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question9")} src={open === "question9" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question9" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Ije is a financial platform that connects African students seeking to study abroad with lenders willing to fund their tuition and living expenses. Lenders provide funding, and students repay over time, helping them build their
                  credit history and financial identity.
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Who can become a lender on Ije?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question10")} src={open === "question10" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question10" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Anyone interested in supporting students and earning returns can lend through Ije. This includes individuals, institutional investors, and financial organizations.
                </div>
              )}
            </div>

            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How does the lending process work?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question11")} src={open === "question11" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question11" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Lenders sign up via{" "}
                  <a className=" text-blue-700 font-medium hover:underline " href="https://www.ijefund.com/auth/lender">
                    Register
                  </a>{" "}
                  and fill in their details. After verification, they can select students to fund and begin earning returns.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What kind of returns can I expect?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question12")} src={open === "question12" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question12" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Lenders receive quarterly interest payments directly into their bank account. For amounts lower than <span className=" text-neutral-700 font-medium">$50</span>, the equivalent interest is paid in{" "}
                  <span className=" text-neutral-700 font-medium">Naira</span>. For amounts <span className=" text-neutral-700 font-medium">$50 and above</span>, lenders can nominate a domiciliary bank account in Nigeria to receive{" "}
                  <span className=" text-neutral-700 font-medium">USD payments</span>. The <span className=" text-neutral-700 font-medium">principal and final interest</span> payment will be made at the end of 12 months.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Is lending through Ije secure?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question13")} src={open === "question13" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question13" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Yes. Ije conducts thorough vetting of students, including academic background, financial need assessment, and repayment capacity. We also provide risk mitigation strategies such as co-financing, guarantees, and structured repayment
                  plans.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What happens if a student defaults on repayment?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question14")} src={open === "question14" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question14" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Ije has mechanisms to manage defaults, including restructuring plans and intervention programs. We also explore partnerships with employers and financial institutions to enhance repayment success.{" "}
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What is the minimum amount I can lend?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question15")} src={open === "question15" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question15" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  The minimum amount a lender can provide is <span className=" text-neutral-700 font-medium">$100</span>, with the equivalent payable in <span className=" text-neutral-700 font-medium">Naira</span>.
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do I track my activities, including lending amount, repayments, and status?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question16")} src={open === "question16" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question16" && <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">Lenders have access to a dashboard where they can monitor loans, repayment schedules, and overall portfolio performance.</div>}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Can I withdraw my funds early?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question17")} src={open === "question17" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question17" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Yes, but <span className=" text-neutral-700 font-medium">50% fee is charged on the interest accrued so far</span> for early withdrawals.
                </div>
              )}
            </div>
            <div className=" w-full flex flex-col justify-start items-start gap-6 ">
              <div className="w-full h-px bg-[#cdd1d4]" />
              <div className=" w-full justify-between items-center flex ">
                <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do I get started as a lender?</h4>
                <button className=" w-[50px] flex justify-end items-end">
                  <img alt="" className="w-[24px]" onClick={() => handleOpenToggle("question18")} src={open === "question18" ? iconClose : iconOpen} />
                </button>
              </div>
              {open === "question18" && (
                <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                  Sign up at{" "}
                  <a className=" text-blue-700 font-medium hover:underline " href="https://www.ijefund.com/auth/lender">
                    Ije
                  </a>{" "}
                  , complete verification, browse student applications, and start lending!
                </div>
              )}
            </div>
          </div>
          <div className="100:w-full relative h-fit xl:w-[1280px] lg:px-8 flex-col justify-start items-start gap-8 inline-flex">
            <div className="w-full h-fit p-8 bg-white rounded-2xl flex-col justify-start items-center gap-8 inline-flex">
              <div className="w-full  flex-col justify-start items-center gap-2 inline-flex">
                <h3 className="self-stretch text-center text-neutral-700 text-xl font-medium  leading-loose">Still have questions?</h3>
                <p className="w-full text-center text-gray-500 text-lg font-normal  leading-7">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
              </div>
              {help && (
                <>
                  <div className=" absolute 100:bottom-36 phone:bottom-20 lg:bottom-28 w-full flex justify-center items-start  h-full">
                    <div className="h-fit   flex 100:w-[90%] phone:w-[416px] px-6 py-5 bg-[#0a205c] rounded-xl shadow flex-col justify-start items-center gap-2.5 ">
                      <div className="w-full justify-end items-center flex">
                        <div onClick={() => setHelp(false)} className="w-[36px] cursor-pointer h-[36px] bg-[#103a9f] rounded-[100px] flex-col justify-center items-center flex">
                          <img className=" " src={cancel} alt="Cancel" />
                        </div>
                      </div>
                      <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                        <div className="w-full flex-col justify-center items-center gap-2 flex">
                          <h2 className="self-stretch text-center text-[#f5f6f6] text-lg font-medium font-inter leading-7">How can we assist you?</h2>
                          <p className="self-stretch text-center text-[#aab0b6] text-sm font-normal font-inter leading-tight">
                            Choose how you'd like to reach us—via WhatsApp, email, or a direct call. <span className="text-[#e5e7e8] text-sm font-medium ">We're here to help!</span>
                          </p>
                        </div>
                        <div className="w-full py-2.5 100:justify-center sm:justify-start 100:flex-wrap sm:flex-nowrap items-start gap-3 inline-flex">
                          {contactUs.map((item) => (
                            <>
                              {item.type === "email" ? (
                                <a href={`mailto:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : item.type === "tel" ? (
                                <a href={`tel:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : item.type === "whatsapp" ? (
                                <a
                                  href={`https://wa.me/${item.contact.replace("+", "")}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  key={item.id}
                                  className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5"
                                >
                                  <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </a>
                              ) : (
                                <div key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                                  <h3 className="ttext-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                                  <img src={item.icon} alt={item.name} className="h-5 w-5" />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* <img className="w-[70px] scale-y-[-1] mt-[-30px] h-[70px]" src={triangle} alt="" /> */}
                  </div>
                </>
              )}
              <button
                onClick={() => setHelp(!help)}
                className=" px-4 py-2.5 hover:bg-blue-800 bg-[#0C4CFF] rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-blue-600 justify-center items-center gap-2 flex overflow-hidden text-white text-base font-medium  leading-normal"
              >
                Get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default FAQ;
