import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import pick from "../../Images/file.svg";

function EmploymentHistory({ data }) {
  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Employment Status
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.employmentStatus
                  ? data?.educationInformation?.employmentStatus
                  : "No Data"}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Years of Work Experience
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.yearsOfWorkExperience
                  ? data?.educationInformation?.yearsOfWorkExperience
                  : "No Data"}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Name of Current Employer
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.currentEmployer
                  ? data?.educationInformation?.currentEmployer
                  : "No Data"}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Role
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.role
                  ? data?.educationInformation?.role
                  : "No Data"}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Number of Months with Employer
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.monthsWithEmployer
                  ? data?.educationInformation?.monthsWithEmployer
                  : "No Data"}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Employment Letter
              </h4>

              {data?.educationInformation?.employmentLetter ? (
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {" "}
                  <a
                    className="w-full"
                    href={data?.educationInformation?.employmentLetter}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-full gap-4 justify-start items-center  inline-flex">
                      <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                        <img src={pick} alt="" />
                      </div>
                      <div className=" flex-col gap-1 justify-start items-start inline-flex">
                        <h4 className="text-[#3a3c3f] text-base font-medium  leading-tight">
                          Employment Letter
                        </h4>
                        <h5 className="text-[#6c757d] text-xs font-normal leading-tight">
                          200 KB
                        </h5>
                      </div>
                    </div>{" "}
                  </a>
                </h3>
              ) : (
                <div>No Data</div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                12 Months Bank Statement
              </h4>

              {data?.educationInformation?.bankStatement ? (
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {" "}
                  <a
                    className="w-full"
                    href={
                      data?.educationInformation?.bankStatement
                    }
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-full gap-4 justify-start items-center  inline-flex">
                      <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                        <img src={pick} alt="" />
                      </div>
                      <div className=" flex-col gap-1 justify-start items-start inline-flex">
                        <h4 className="text-[#3a3c3f] text-base font-medium  leading-tight">
                          12 Months Bank Statement
                        </h4>
                        <h5 className="text-[#6c757d] text-xs font-normal leading-tight">
                          200 KB
                        </h5>
                      </div>
                    </div>{" "}
                  </a>
                </h3>
              ) : (
                <div>No Data</div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="w-full  justify-center pb-[20px] border-b border-b-[#e5e7e8] items-center gap-5 flex">
          <h4 className=" text-[#6c757d] text-sm font-normal font-inter leading-tight">
            Previous Work Experience
          </h4>
        </div>{" "}
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Name of Employer
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.currentEmployer}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Role
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.position}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Number of Years with Employer
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {data?.educationInformation?.yearsOfWorkExperience}
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Employment Letter
              </h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {" "}
                <a
                  className="w-full"
                  href={data?.educationInformation?.employmentLetter}
                  download={true}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="w-full gap-4 justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" flex-col gap-1 justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium  leading-tight">
                        Employment Letter
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal leading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>{" "}
                </a>
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default EmploymentHistory;
