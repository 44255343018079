import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import geometiricShapes from "../Images/GeometricShapes.svg";
import WhyChooseIje from "./WhyChooseIje";
import HowIjeWorks from "./HowIjeWorks";
// import FeaturedCampaigns from "./FeaturedCampaigns";
import SuccessStory from "./SuccessStory";
import EmpowerDreams from "./EmpowerDreams";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import { useRef } from "react";
// import FAQ from "./FAQ";
import Lender from "./Lender";
// import HowitWorksLender from "./HowitWorksLender";
import WhyLenders from "./WhyLenders";

function Homepage() {
  const howIjeWorks = useRef(null);
  // const whyChooseIje = useRef(null);
  // const campaigns = useRef(null);
  const contact = useRef(null);
  // const faq = useRef(null);
  const home = useRef(null);
  const lender = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  // const location = useLocation();

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const section = queryParams.get("section");

  //   if (section === "contact" && contact.current) {
  //     scrollToSection(contact);
  //   }
  //   // Add more sections as needed
  // }, [location]);
  return (
    <>
      <Header scrollToSection={scrollToSection} lender={lender} home={home} contact={contact} howIjeWorks={howIjeWorks} />
      <section ref={home}>
        <section className="w-full mt-[96px] py-[96px] h-fit flex justify-center items-center ">
          <div className=" flex flex-col justify-center items-center gap-[64px] 100:w-[90%] lg:w-[1000px]">
            <div className=" flex flex-col justify-center items-center gap-[32px] ">
              <div className="flex flex-col justify-center items-center gap-[24px]">
                <section className=" gap-[16px] text-center w-full flex flex-col justify-center items-center">
                  <div className="w-40 h-8 p-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-center items-center gap-3 inline-flex">
                    {" "}
                    <h3 className="text-center text-blue-600 text-sm font-medium font-['Inter'] leading-tight">Get Started Today!</h3>
                  </div>
                  <h1 className=" 100:w-full md:w-[668px] font-medium tracking-[-2px] text-[#3A3C3F] 100:text-[40px] 100:leading-[48px] md:leading-[82px] md:text-[64px]">
                    {" "}
                    Make Study Abroad Possible with <span className="text-[#0C4CFF]">Ije</span>
                  </h1>
                </section>
                <p className="md:text-[20px] text-center 100:text-[16px] md:leading-[30px] 100:leading-[23px] text-[#6C757D] 100:w-[80%] xl:w-[739px] font-normal">
                  {/* Join Ije to access the support you need for your post-graduate study abroad. */}
                  As a student, you can create a campaign to fund your education through donations and loans. As a lender, you can empower a student’s future by providing financial support through education loans.{" "}
                </p>
              </div>
              <section className=" 100:flex-col 100:w-full phone:w-fit phone:flex-row  flex justify-center items-center gap-[12px]">
                <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to="/auth/register">
                  <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px] 100:text-sm bg-[#0C4AFF] md:text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 100:w-full phone:w-fit">
                    {" "}
                    Get Started as a Student
                  </div>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to="/auth/lender">
                  <div className=" shadow border border-gray-400 leading-[20px] 100:text-sm md:text-lg   rounded-[8px]  text-[#4A4F54] flex justify-center font-medium h-fit px-7 py-4 100:w-full phone:w-fit"> Get Started as a Lender</div>
                </Link>
              </section>
            </div>
            <div className=" w-full ">
              <img className=" w-full" src={geometiricShapes} alt="Shapes" />
            </div>
          </div>
        </section>
      </section>
      <div>
        <WhyChooseIje />
      </div>
      <section ref={howIjeWorks}>
        <HowIjeWorks />
      </section>
      <section ref={lender}>
        <Lender />
      </section>
      {/* <HowitWorksLender /> */}
      <WhyLenders />
      {/* <div ref={campaigns}>
        <FeaturedCampaigns />
      </div> */}
      <div>
        <SuccessStory />
      </div>
      <EmpowerDreams />
      {/* <section ref={faq}>
        <FAQ />
      </section> */}

      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default Homepage;
