import { useAppSelector } from "../../../utils/redux/store";
import user from "../../Images/userID.png";

import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";

function BasicInfo() {
  const {
    imageString,
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    dob,

    bvnValidate,
  } = useAppSelector((state) => state.auth);

  return (
    <>
      <div className="h-fit w-full  flex-col justify-start items-start inline-flex">
        <div className=" w-full 100:h-fit 100:flex-col sm:flex-row sm:h-[620px] sm:pr-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-8 inline-flex">
          <div className=" 100:w-full 100:hidden md:flex sm:w-[175px] h-full p-5 bg-[#e5e7e8] rounded-tl-lg md:rounded-bl-lg justify-center items-start gap-2.5 flex">
            <img className="w-24 h-24  relative rounded-[200px] shadow border-4 border-white" src={imageString ? imageString : user} />
          </div>
          <div className="h-fit 100:px-[24px] sm:px-0 w-full 100:py-6 sm:py-6 flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Full Name</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">
                    {firstName} {lastName}
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Gender</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">{gender ? gender : <div>No Data</div>}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Date of Birth</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">{dob ? dob : <div>No Data</div>}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Email Address</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">{email}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Phone Number</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">{phoneNumber}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Bank Verification Number (BVN)</h4>

                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-tight">{bvnValidate ? "Verified" : <div> Unverified</div>}</h3>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px]  items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal  leading-tight">Status</h4>
                  {/* {acceptanceStatus === "ACCEPT" ? ( */}
                  <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                    {" "}
                    <Completed /> Completed
                  </div>
                  {/* ) : acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject /> Rejected
                    </div>
                  ) : (
                    <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending /> Pending Approval
                    </div>
                  )}{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInfo;
