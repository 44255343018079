import Header from "./Header";
import group from "../Images/Group2.png";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import WhatAndWhy from "./WhatAndWhy";
import { useRef } from "react";

function AboutUs() {
  const contact = useRef(null);
  // const faq = useRef(null);
  const lender = useRef(null);
  const home = useRef(null);
  const howIjeWorks = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header scrollToSection={scrollToSection} home={home} lender={lender} contact={contact} howIjeWorks={howIjeWorks} />
      <div className=" h-fit  mt-[96px] w-full py-[96px] flex justify-center items-center">
        <section className="100:w-[85%] xl:w-[1216px] 100:flex-col lg:flex-row   lg:w-full lg:px-[32px] gap-[64px] flex justify-between items-start  ">
          <div className=" 100:w-full lg:w-[572px]  gap-[40px] text-center flex flex-col items-start justify-start">
            <section className="  gap-[20px] text-left flex flex-col items-start justify-start">
              <div className="  gap-[12px] text-left flex flex-col items-start justify-start">
                <h5 className=" text-blue-700 text-base font-medium  leading-tight">About us</h5>

                <h2 className=" font-semibold  text-[#3A3C3F] text-[48px]  tracking-[-2px] leading-[60px] ">Empowering African Students to Achieve Global Education</h2>
              </div>
              <p className="text-[20px]  leading-[30px]  text-[#6C757D] text-justify w-full font-normal">
                <b>We believe</b> n the transformative power of education and the limitless potential of African students. Our mission is to break down financial barriers that prevent talented Africans from pursuing postgraduate studies abroad. By
                blending community-driven fundraising with flexible loan options from trusted lenders, we provide a sustainable solution that ensures every African student can focus on their academic journey without the weight of financial
                constraints. <br /> Ije is more than just a platform—we are a community committed to creating opportunities and fostering the dreams of the next generation of African leaders.
              </p>
            </section>
          </div>
          <div className=" lg:w-[572px] 100:w-full">
            <img className=" w-full" src={group} alt="Ije" />
          </div>
        </section>
      </div>{" "}
      <WhatAndWhy />
      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;
