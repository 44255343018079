import what from "../Images/WhatWeDo.png";
import why from "../Images/WhyWeExist.png";

function WhatAndWhy() {
  return (
    <>
      <div className="h-fit w-full 100:px-8 md:px-16 py-24 bg-[#103a9f] flex-col justify-center items-center gap-[78px] inline-flex">
        <div className=" 100:w-full 2xl:w-[1280px] 100:flex-col lg:flex-row flex justify-start items-center 100:gap-[32px] 2xl:gap-[64px] ">
          <div className=" w-full">
            <img src={what} alt="What we do" />
          </div>
          <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
            <h2 className="self-stretch text-[#f5f6f6] text-3xl font-semibold leading-[38px]">What We Do</h2>
            <div className="text-[#cdd1d4] text-justify text-lg font-normal  leading-7">
              <span className="text-[#e5e7e8] text-lg font-semibold ">We provide</span> a web-based co-financing platform that helps African students fund their postgraduate studies abroad. Through personalized fundraising campaigns, students can
              receive donations from family, friends, and the wider Ije community. <br />
              <br />
              When donations don’t fully cover the required amount, we bridge the gap by offering tailored loans through our network of partner lenders. These loans are designed with flexible repayment terms of up to five years, ensuring students can
              complete their studies without financial strain.
              <br />
              <br />
              From the moment students are accepted into a foreign university to the completion of their studies, Ije provides a <b>100% financial solution</b>—so they can focus on what truly matters: their education.
            </div>
          </div>
        </div>
        <div className=" 100:w-full 2xl:w-[1280px] 100:flex-col lg:flex-row flex justify-start items-center 100:gap-[32px] 2xl:gap-[64px] ">
          <div className=" w-full">
            <img src={why} alt="What we do" />
          </div>
          <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
            <h2 className="self-stretch text-[#f5f6f6] text-3xl font-semibold leading-[38px]">Why We Exist</h2>
            <div className="text-[#cdd1d4] text-justify text-lg font-normal  leading-7">
              <span className="text-[#e5e7e8] text-lg font-semibold ">Our Mission</span> is to make international education more accessible Africans <br />
              <br />
              For many African students, the dream of obtaining a world-class education is often out of reach due to financial barriers. The cost of tuition, travel, living expenses, and visa fees can be overwhelming, especially for students from
              emerging economies.
              <br />
              <br />
              Traditional financing options, such as bank loans, often come with high interest rates and strict collateral requirements—making them inaccessible to many students. By partnering with a network of trusted lenders and leveraging the
              power of community-driven support, Ije creates a sustainable and inclusive pathway for African students to achieve their academic goals.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatAndWhy;
