import { useAppDispatch, useAppSelector } from "../../../utils/redux/store";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
// import edit from "../../Images/editReg.svg";
import pick from "../../Images/file.svg";
import { useFormik } from "formik";
import { useState } from "react";
import TextInput from "../../shared/input/TextInput";
import { EditButton } from "./EditButton";
import { SaveCancelButton } from "./SaveCancelButton";
import { toast } from "react-toastify";
import EducationRequest from "../../../utils/services/educationRequests";
import FileInput from "../../shared/input/FileInput";
import UserRequest from "../../../utils/services/userRequets";
import { setUser } from "../../../utils/redux/authSlice";
function IdentityVerification() {
  const { acceptanceStatus, educationInformation, email } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [linkedinEdit, setLinkedinEdit] = useState(false);
  const [bvnEdit, setBvnEdit] = useState(false);
  const [passportEdit, setPassportEdit] = useState(false);
  const [passportNumEdit, setPassportNumEdit] = useState(false);
  const [twitterEdit, setTwitterEdit] = useState(false);
  const [instaEdit, setInstEdit] = useState(false);
  const [faceEdit, setFaceEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      linkedInAddress: educationInformation?.linkedInAddress,
      passportNumber: educationInformation?.passportNumber,
      bvn: educationInformation?.bvn,
      internationalPassport: educationInformation?.internationalPassport,
      twitterLink: educationInformation?.twitterLink,
      instagramLink: educationInformation?.instagramLink,
      facebookProfile: educationInformation?.facebookProfile,
    },
    onSubmit: async (values) => {
      // console.log(values);

      const payload = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      setLoading(true);
      await EducationRequest.registerEducationedit(payload)
        .then(async (res) => {
          setLoading(false);
          // ;
          if (res.data.statusCode === 200) {
            await UserRequest.getUserDetials(email).then((res) => {
              if (res.data.statusCode === 200) {
                localStorage.setItem("ijeDetails", JSON.stringify(res.data.result));
                dispatch(setUser({ ...res.data.result }));

                toast.success("Information Updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });

                setLinkedinEdit(false);
                setBvnEdit(false);
                setPassportEdit(false);
                setPassportNumEdit(false);
                setTwitterEdit(false);
                setInstEdit(false);
                setFaceEdit(false);
              } else {
                toast.error(res?.data?.message || res?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
              }
            });
          } else {
            setLoading(false);

            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }

          // setPage(page + 1)
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        formik.setFieldValue(name, base64String);
        // console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  console.log(acceptanceStatus);
  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">Linkedin profile</h4>
                {linkedinEdit ? (
                  <>
                    <TextInput
                      label={""}
                      type="text"
                      value={formik.values.linkedInAddress}
                      onChange={formik.handleChange("linkedInAddress")}
                      onBlur={formik.handleBlur("linkedInAddress")}
                      placeholder="www.linkedin.com"
                      error={formik.touched.linkedInAddress && formik.errors.linkedInAddress}
                    />
                  </>
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{educationInformation?.linkedInAddress}</h3>
                  </>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null : linkedinEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setLinkedinEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setLinkedinEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">International Passport Number</h4>

                {passportNumEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.passportNumber}
                    onChange={formik.handleChange("passportNumber")}
                    onBlur={formik.handleBlur("passportNumber")}
                    placeholder="International Passport Number"
                    error={formik.touched.passportNumber && formik.errors.passportNumber}
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">International Passport Number</h3>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null : passportNumEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setPassportNumEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setPassportNumEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">Copy of International Passport</h4>

                {passportEdit ? (
                  <FileInput label={"Upload Copy of International Passport"} error={formik.errors.internationalPassport} onChange={handleFileChange} name="internationalPassport" />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">International Passport</h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                    </div>
                  </div>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null : passportEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setPassportEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setPassportEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">X profile</h4>
                {twitterEdit ? (
                  <>
                    <TextInput
                      label={""}
                      type="text"
                      value={formik.values.twitterLink}
                      onChange={formik.handleChange("twitterLink")}
                      onBlur={formik.handleBlur("twitterLink")}
                      placeholder="www.x.com"
                      error={formik.touched.twitterLink && formik.errors.twitterLink}
                    />
                  </>
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{educationInformation?.twitterLink ? educationInformation?.twitterLink : <div>No Data</div>}</h3>
                  </>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null : twitterEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setTwitterEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setTwitterEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">Instagram profile</h4>
                {instaEdit ? (
                  <>
                    <TextInput
                      label={""}
                      type="text"
                      value={formik.values.instagramLink}
                      onChange={formik.handleChange("instagramLink")}
                      onBlur={formik.handleBlur("instagramLink")}
                      placeholder="www.instagram.com"
                      error={formik.touched.instagramLink && formik.errors.instagramLink}
                    />
                  </>
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{educationInformation?.instagramLink ? educationInformation?.instagramLink : "No Data"}</h3>
                  </>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null : instaEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setInstEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setInstEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">Facebook profile</h4>
                {faceEdit ? (
                  <>
                    <TextInput
                      label={""}
                      type="text"
                      value={formik.values.facebookProfile}
                      onChange={formik.handleChange("facebookProfile")}
                      onBlur={formik.handleBlur("facebookProfile")}
                      placeholder="www.facebook.com"
                      error={formik.touched.facebookProfile && formik.errors.facebookProfile}
                    />
                  </>
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{educationInformation?.facebookProfile ? educationInformation?.facebookProfile : "No Data"}</h3>
                  </>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null : faceEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setFaceEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setFaceEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px]  items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] 100:text-xs md:text-sm font-normal font-inter leading-tight">Status</h4>
              {acceptanceStatus === "PENDING" ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Pending /> Pending Approval
                </div>
              ) : educationInformation?.stage > 1 && !educationInformation?.failedPages?.includes(1) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                  {" "}
                  <Completed /> Completed
                </div>
              ) : educationInformation?.failedPages?.includes(1) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Reject /> Rejected
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IdentityVerification;
