import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../Images/unsplash1.png";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
import { ReactComponent as Logo } from "../Images/Logo-with-text.svg";
import upload from "../Images/upload.svg";
import media from "../Images/media.svg";
import InputFiles from "react-input-files";
import Select from "react-select";
import cancel from "../Images/Cancel.svg";
import confirm from "../Images/Confirm.svg";
import { useFormik } from "formik";
import user from "../Images/userID.png";
import { createEducationValidator } from "../../utils/services/validation";
import EducationRequest from "../../utils/services/educationRequests";
import { countryData, courseData, currencyData, durationStudyData, employmentMonths, employmentStatusData, exploymentRole, uniData, yearsofExperienceData } from "../../utils/helpers/constants";
import { useAppSelector } from "../../utils/redux/store";
import TextInput from "../shared/input/TextInput";
import { toast } from "react-toastify";
import FileInput from "../shared/input/FileInput";

function SignUp() {
  const [previousWork, setPreviousWork] = useState(false);
  const [page, setPage] = useState(1);
  const [page1Fields, setPage1Fields] = useState({});
  const [page2Fields, setPage2Fields] = useState({});
  const [page3Fields, setPage3Fields] = useState({});
  const [page4Fields, setPage4Fields] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { email } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  // const mail = localStorage.getItem("emailData");
  const formik = useFormik({
    validationSchema: createEducationValidator,
    initialValues: {
      email: email,
      linkedInAddress: "",
      passportNumber: "",
      // bvn: "",
      internationalPassport: "",
      twitterLink: "",
      instagramLink: "",
      facebookProfile: "",
      university: "",
      certificate: "",
      transcript: "",
      schoolAdmitted: "",
      courseOfStudy: "",
      country: "",
      durationOfStudy: "",
      admissionLetter: "",
      employmentStatus: "",
      yearsOfWorkExperience: "",
      currentEmployer: "",
      role: "",
      monthsWithEmployer: "",
      employmentLetter: "",
      bankStatement: "",
      currency: "",
      tuitionFee: "",
      livingExpenses: "",
      totalFundingRequirement: "",
      amountAvailable: "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      // console.log(values);

      await EducationRequest.registerEducation(
        values.email,
        values.linkedInAddress,
        values.passportNumber,
        // values.bvn,
        values.internationalPassport,
        values.twitterLink,
        values.instagramLink,
        values.facebookProfile,
        values.university,
        values.certificate,
        values.transcript,
        values.schoolAdmitted,
        values.courseOfStudy,
        values.country,
        values.durationOfStudy,
        values.admissionLetter,
        values.employmentStatus,
        values.yearsOfWorkExperience,
        values.currentEmployer,
        values.role,
        values.monthsWithEmployer,
        values.employmentLetter,
        values.bankStatement,
        values.currency,
        values.tuitionFee,
        values.livingExpenses,
        values.totalFundingRequirement,
        values.amountAvailable
      ).then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          // setModal(true);
        } else {
          console.log("erro");
        }
      });

      // setLoading(false);
    },
  });
  //  const total = formik.values.tuitionFee + formik.values.livingExpenses;
  useEffect(() => {
    const total = parseFloat(formik.values.tuitionFee) + parseFloat(formik.values.livingExpenses);
    formik.setFieldValue("totalFundingRequirement", total);
  }, [formik.values.tuitionFee, formik.values.livingExpenses]);

  const handleNavigation = () => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      navigate("/user/dashboard");
    }
  };
  const role = [{ label: "", value: "" }];
  const noOfMonths = [{ label: "", value: "" }];

  const handleFieldChange = (page, fieldName, value) => {
    switch (page) {
      case 1:
        setPage1Fields({ ...page1Fields, [fieldName]: value });
        break;
      case 2:
        setPage2Fields({ ...page2Fields, [fieldName]: value });
        break;
      case 3:
        setPage3Fields({ ...page3Fields, [fieldName]: value });
        break;
      case 4:
        setPage4Fields({ ...page4Fields, [fieldName]: value });
        break;
      default:
        break;
    }
  };

  const progressBar = "100%";
  const Stage1Func = async () => {
    setLoading(true);
    await EducationRequest.registerEducationOne(
      formik.values.linkedInAddress,
      formik.values.passportNumber,
      // formik.values.bvn,
      formik.values.internationalPassport,
      formik.values.twitterLink,
      formik.values.instagramLink,
      formik.values.facebookProfile
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPage(page + 1);
        } else {
          setLoading(false);
          setErrorMessage(res.data.message);
          setSuccess(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }

        // setPage(page + 1)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Stage2Func = async () => {
    setLoading(true);
    await EducationRequest.registerEducationTwo(
      formik.values.university,
      formik.values.certificate,
      formik.values.transcript,
      formik.values.schoolAdmitted,
      formik.values.courseOfStudy,
      formik.values.country,
      formik.values.durationOfStudy,
      formik.values.admissionLetter
    )
      .then((res) => {
        // ;
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPage(page + 1);
        } else {
          setLoading(false);
          setErrorMessage(res.data.message);
          setSuccess(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }

        // setPage(page + 1)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const Stage3Func = async () => {
    setLoading(true);
    await EducationRequest.registerEducationThree(
      formik.values.email,
      formik.values.employmentStatus,
      formik.values.yearsOfWorkExperience,
      formik.values.currentEmployer,
      formik.values.role,
      formik.values.monthsWithEmployer,
      formik.values.employmentLetter,
      formik.values.bankStatement
    )
      .then((res) => {
        // ;
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPage(page + 1);
        } else {
          setLoading(false);
          setErrorMessage(res.data.message);
          setSuccess(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }

        // setPage(page + 1)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const Stage4Func = async () => {
    setLoading(true);
    await EducationRequest.registerEducationFour(formik.values.email, formik.values.currency, formik.values.tuitionFee, formik.values.livingExpenses, formik.values.totalFundingRequirement, formik.values.amountAvailable)
      .then((res) => {
        // ;
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setModal(true);
        } else {
          setLoading(false);
          setErrorMessage(res.data.message);
          setSuccess(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }

        // setPage(page + 1)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const moveNext = () => {
    switch (page) {
      case 1:
        Stage1Func();

        break;
      case 2:
        Stage2Func();
        break;
      case 3:
        Stage3Func();
        break;
      case 4:
        Stage4Func();
        break;

      default:
        break;
    }
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);
        formik.setFieldValue(name, base64String);
        // console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  // console.log(formik.values);

  const isvalid1 =
    formik.values.linkedInAddress &&
    formik.values.passportNumber &&
    // formik.values.bvn &&
    formik.values.internationalPassport;

  const isvalid2 =
    formik.values.university && formik.values.certificate && formik.values.transcript && formik.values.schoolAdmitted && formik.values.courseOfStudy && formik.values.country && formik.values.durationOfStudy && formik.values.admissionLetter;

  const isvalid3 = formik.values.employmentStatus && formik.values.yearsOfWorkExperience && formik.values.currentEmployer && formik.values.role && formik.values.monthsWithEmployer && formik.values.employmentLetter && formik.values.bankStatement;

  const isvalid4 = formik.values.currency && formik.values.tuitionFee && formik.values.livingExpenses && formik.values.totalFundingRequirement && formik.values.amountAvailable;
  return (
    <>
      <div className=" bg-white 100:flex-col lg:flex-row h-full w-full  100:justify-start 100:items-center flex xl:justify-start xl:items-start p-[30px]">
        <div className=" 100:hidden overflow-y-scroll xl:flex bg-[#0A205C] fixed top-[20px] left-[30px] xl:w-[685px]  rounded-[30px] p-[50px]  flex-col gap-[60px] justify-between h-[95%] ">
          <div className=" flex w-full flex-col justify-start items-start gap-[53px]">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <Logo style={{ color: "#fff" }} />
            </Link>
            <div className=" flex w-full flex-col justify-start items-start gap-[32px]">
              {page === 1 && (
                <>
                  <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Verify Your Identity and Connect Your Profile</h2>
                  <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">
                    To create a secure community, we need to verify your identity and professional background. Please provide the necessary details, upload required documents, and connect your LinkedIn profile.{" "}
                  </p>
                </>
              )}
              {page === 2 && (
                <>
                  <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Educational Background and Future Plans</h2>
                  <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">
                    Share your educational journey and future plans. Provide details of your past education and the institution you plan to attend, and upload the necessary documents.{" "}
                  </p>
                </>
              )}
              {page === 3 && (
                <>
                  <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Employment Information</h2>
                  <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">
                    Share your current and past employment details to highlight your professional experience. Upload required documents for verification. You can add multiple previous jobs.{" "}
                  </p>
                </>
              )}
              {page === 4 && (
                <>
                  <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Financial Requirements and Available Funds</h2>
                  <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">Outline your financial needs and available funds. Ensure you have at least 10% of the total funding requirement. </p>
                </>
              )}
            </div>
          </div>
          <div className=" flex-col flex justify-start items-start gap-[16px] bg-[#56A1FF] h-[150px] p-[20px] w-full rounded-[20px]">
            <p className=" font-normal text-[16px]  leading-[145%]  text-[#D5EFFF] ">"With Ije’s flexible repayment terms, I am able to pursue my academic goals of having an international education."</p>
            <div className="flex w-full gap-[12px] justify-start items-center">
              <img src={user} alt="User" />
              <span className="flex flex-col w-full gap-[5px] justify-start items-start">
                <h4 className=" font-normal text-[14px] leading-[110%]  text-[#fff] ">Titilope Ogunsesimi</h4>
                <p className=" font-normal text-[10px] leading-[110%]  text-[#E5E7E8] ">Malta</p>
              </span>
            </div>
          </div>
        </div>
        <div className=" z-50 100:flex xl:hidden bg-[#0A205C] fixed top-[20px] w-[90%] gap-[12px]  rounded-full p-[8px] item-center justify-center h-fit ">
          <div className="w-[50px] flex justify-center items-center ">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img className="text-[#fff] fill-white " src={logo} alt="IJE" />
            </Link>
          </div>
          {/* SMALL SCREENS HEADER */}
          <div className="w-fit flex justify-center items-center ">
            <h2 className=" font-semibold text-[16px] leading-[24px]  text-[#fff] ">
              {page === 1 && <>Verify Your Identity and Connect Your Profile</>}
              {page === 2 && <>Educational Background and Future Plans</>}
              {page === 3 && <>Employment Information</>}
              {page === 4 && <>Financial Requirements and Available Funds</>}
            </h2>
          </div>
        </div>
        <div className="100:w-[90%] 100:pt-[90px] xl:pt-[50px] h-fit xl:w-full 100:gap-8 md:gap-[70px] xl:pl-[715px] flex flex-col justify-center items-center py-[50px] pr-[10px]">
          <div className=" w-full flex justify-between items-center ">
            <Link onClick={handleNavigation} to={""}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <h4 className=" text-[14px] leading-[24px] text-[#6C757D] font-normal">{page} of 4</h4>
          </div>
          <div className=" 100:w-full sm:w-[480px] 100:gap-[24px] md:gap-[32px] flex-col flex justify-start items-start ">
            <h4 className=" font-semibold 100:text-xl md:text-[30px] text-[#3A3C3F] leading-[38px] ">Let's Complete Your Registration</h4>
            {/* FORMS */}
            <div className="flex-col  w-full flex gap-[32px] justify-start items-start ">
              {page === 1 && (
                <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                  <TextInput
                    label={"Linkedin profile"}
                    type="text"
                    value={formik.values.linkedInAddress}
                    onChange={formik.handleChange("linkedInAddress")}
                    onBlur={formik.handleBlur("linkedInAddress")}
                    placeholder="www.linkedin.com"
                    error={formik.touched.linkedInAddress && formik.errors.linkedInAddress}
                    hint={" Note: LinkedIn profile is compulsory"}
                  />

                  <TextInput
                    label={"International Passport Number"}
                    type="text"
                    value={formik.values.passportNumber}
                    onChange={formik.handleChange("passportNumber")}
                    onBlur={formik.handleBlur("passportNumber")}
                    placeholder="International Passport Number"
                    error={formik.touched.passportNumber && formik.errors.passportNumber}
                  />

                  {/* <TextInput
                    label={"Bank Verification Number (BVN)"}
                    type="text"
                    value={formik.values.bvn}
                    onChange={formik.handleChange("bvn")}
                    onBlur={formik.handleBlur("bvn")}
                    placeholder="xxxxxxxxxxx"
                    error={formik.touched.bvn && formik.errors.bvn}
                    limit={11}
                  /> */}

                  <FileInput label={"Upload Copy of International Passport"} error={formik.errors.internationalPassport} onChange={handleFileChange} name="internationalPassport" />

                  <TextInput
                    label={"Twitter profile (Optional)"}
                    type="text"
                    value={formik.values.twitterLink}
                    onChange={formik.handleChange("twitterLink")}
                    onBlur={formik.handleBlur("twitterLink")}
                    placeholder="www.twitter.com"
                    error={formik.touched.twitterLink && formik.errors.twitterLink}
                  />

                  <TextInput
                    label={"Instagram profile (Optional)"}
                    type="text"
                    value={formik.values.instagramLink}
                    onChange={formik.handleChange("instagramLink")}
                    onBlur={formik.handleBlur("instagramLink")}
                    placeholder="www.instagram.com"
                    error={formik.touched.instagramLink && formik.errors.instagramLink}
                  />

                  <TextInput
                    label={"   Facebook profile (Optional)"}
                    type="text"
                    value={formik.values.facebookProfile}
                    onChange={formik.handleChange("facebookProfile")}
                    onBlur={formik.handleBlur("facebookProfile")}
                    placeholder="www.facebook.com"
                    error={formik.touched.facebookProfile && formik.errors.facebookProfile}
                  />
                </form>
              )}
              {page === 2 && (
                <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                  {/* <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor=" University/Polytechnic Attended">
                    University/Polytechnic Attended
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select university/polytechnic attended"
                      options={uniData}
                      onChange={(e) => {
                        formik.setFieldValue("university", e.value);
                      }}
                    />
                  </label> */}

                  <TextInput
                    label={"University/Polytechnic Attended"}
                    type="text"
                    value={formik.values.university}
                    onChange={formik.handleChange("university")}
                    onBlur={formik.handleBlur("university")}
                    placeholder="University/Polytechnic Attended"
                    error={formik.touched.university && formik.errors.university}
                  />

                  <FileInput label={"Attach Certificate"} error={formik.errors.certificate} onChange={handleFileChange} name="certificate" />

                  <FileInput label={"Attach Transcript"} error={formik.errors.transcript} onChange={handleFileChange} name="transcript" />

                  <TextInput
                    label={"School Admitted To"}
                    type="text"
                    value={formik.values.schoolAdmitted}
                    onChange={formik.handleChange("schoolAdmitted")}
                    onBlur={formik.handleBlur("schoolAdmitted")}
                    placeholder="School Admitted To"
                    error={formik.touched.schoolAdmitted && formik.errors.schoolAdmitted}
                  />

                  <TextInput
                    label={"Course of Study"}
                    type="text"
                    value={formik.values.courseOfStudy}
                    onChange={formik.handleChange("courseOfStudy")}
                    onBlur={formik.handleBlur("courseOfStudy")}
                    placeholder="Course of Study"
                    error={formik.touched.courseOfStudy && formik.errors.courseOfStudy}
                  />

                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="  Country">
                    Country
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select country"
                      name="country"
                      options={countryData}
                      onChange={(e) => {
                        formik.setFieldValue("country", e.value);
                      }}
                    />
                  </label>

                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="  Country">
                    Duration of Study
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Duration of Study"
                      name="durationOfStudy"
                      options={durationStudyData}
                      onChange={(e) => {
                        formik.setFieldValue("durationOfStudy", e.value);
                      }}
                    />
                  </label>
                  <FileInput label={"Upload Admission Letter"} error={formik.errors.admissionLetter} onChange={handleFileChange} name="admissionLetter" />
                </form>
              )}
              {page === 3 && (
                <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                  {/* <TextInput
                    label={" Employment Status"}
                    type="text"
                    value={formik.values.employmentStatus}
                    onChange={formik.handleChange("employmentStatus")}
                    onBlur={formik.handleBlur("employmentStatus")}
                    placeholder=" Employment Status"
                    error={formik.touched.employmentStatus && formik.errors.employmentStatus}
                  /> */}
                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="employmentStatus">
                    Employment Status
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select Status"
                      // options={currency}
                      options={employmentStatusData}
                      onChange={(e) => {
                        formik.setFieldValue("employmentStatus", e.value);
                        formik.setFieldValue("employmentStatus", e.value);
                      }}
                    />
                  </label>

                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="yearsOfWorkExperience">
                    Years of Work Experience
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select"
                      // options={currency}
                      options={yearsofExperienceData}
                      onChange={(e) => {
                        formik.setFieldValue("yearsOfWorkExperience", e.value);
                        formik.setFieldValue("yearsOfWorkExperience", e.value);
                      }}
                    />
                  </label>

                  <TextInput
                    label={"Name of Current Employer"}
                    type="text"
                    value={formik.values.currentEmployer}
                    onChange={formik.handleChange("currentEmployer")}
                    onBlur={formik.handleBlur("currentEmployer")}
                    placeholder="Name of Current Employer"
                    error={formik.touched.currentEmployer && formik.errors.currentEmployer}
                  />

                  <TextInput label={"Role"} type="text" value={formik.values.role} onChange={formik.handleChange("role")} onBlur={formik.handleBlur("role")} placeholder="Role" error={formik.touched.role && formik.errors.role} />

                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="monthsWithEmployer">
                    Number of Months with Employer
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select"
                      // options={currency}
                      options={employmentMonths}
                      onChange={(e) => {
                        formik.setFieldValue("monthsWithEmployer", e.value);
                        formik.setFieldValue("monthsWithEmployer", e.value);
                      }}
                    />
                  </label>

                  <FileInput label={"Upload Employment Letter"} error={formik.errors.employmentLetter} onChange={handleFileChange} name="employmentLetter" />

                  <FileInput label={"Upload 12 Months Bank Statement"} error={formik.errors.bankStatement} onChange={handleFileChange} name="bankStatement" />

                  {/* <label className=" w-full flex  justify-start items-start gap-[12px] leading-[20px] text-[#4A4F54] text-[14px] font-normal " htmlFor="Add Previous Work Experience">
                    <input onClick={() => setPreviousWork(!previousWork)} className="bg-white cursor-pointer border-[#CDD1D4] border w-[20px] h-[20px] rounded-[6px]" type="checkbox" /> Add Previous Work Experience
                  </label> */}

                  {previousWork && (
                    <>
                      <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Name of Employer">
                        Name of Employer
                        <input
                          required
                          className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                          type="text"
                          placeholder="Name of current employer"
                        />
                      </label>
                      <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Role">
                        Role
                        <Select
                          className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "100%",
                              height: "48px",
                              background: "#fff",
                              outline: "none",
                            }),
                          }}
                          placeholder="Select role"
                          options={role}
                        />
                      </label>
                      <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor=" Number of Months with Employer">
                        Number of Months with Employer
                        <Select
                          className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "100%",
                              height: "48px",
                              background: "#fff",
                              outline: "none",
                            }),
                          }}
                          placeholder="Select number of months with employer"
                          options={noOfMonths}
                        />
                      </label>

                      <FileInput label={"      Upload Employment Letter"} error={formik.errors.employmentLetter} onChange={handleFileChange} name="employmentLetter" />
                    </>
                  )}
                </form>
              )}
              {page === 4 && (
                <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor=" Currency">
                    Currency
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select currency"
                      // options={currency}
                      options={currencyData}
                      onChange={(e) => {
                        formik.setFieldValue("currency", e.value);
                        formik.setFieldValue("currency", e.value);
                      }}
                    />
                  </label>

                  <TextInput
                    label={"Tuition Fee"}
                    type="text"
                    value={formik.values.tuitionFee}
                    onChange={formik.handleChange("tuitionFee")}
                    onBlur={formik.handleBlur("tuitionFee")}
                    placeholder="Tuition Fee"
                    error={formik.touched.tuitionFee && formik.errors.tuitionFee}
                  />

                  <TextInput
                    label={`Living Expenses (Required by the embassy)`}
                    type="text"
                    value={formik.values.livingExpenses}
                    onChange={formik.handleChange("livingExpenses")}
                    onBlur={formik.handleBlur("livingExpenses")}
                    placeholder="Living expenses"
                    error={formik.touched.livingExpenses && formik.errors.livingExpenses}
                  />

                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor=" Total Funding Requirement ">
                    Total Funding Requirement
                    <div className="w-full border flex justify-between items-center  bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F]  font-normal leading-[24px] text-[16px]   ">
                      <div className="w-14 h-full  rounded-t-lg rounded-b-lg justify-center items-center flex">$</div>
                      <input
                        required
                        // value={inputValue.input21 + inputValue.input22}
                        className="w-full border-b border-t border-r bg-white outline-[#0C4AFF] border-[#CDD1D4] h-[48px] px-[16px]   text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                        type="number"
                        placeholder="Total Amount"
                        value={formik.values.totalFundingRequirement}
                        disabled
                        // onChange={formik.handleChange(
                        //   "totalFundingRequirement"
                        // )}
                        // onBlur={formik.handleBlur("totalFundingRequirement")}
                      />
                    </div>
                  </label>
                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor=" State the Amount You Have Available">
                    State the Amount You Have Available
                    <div className="w-full border flex justify-between items-center  bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px]   ">
                      <div className="w-14 h-full  rounded-t-lg rounded-b-lg justify-center items-center flex">US</div>
                      <input
                        required
                        className="w-full border-b border-t border-r bg-white outline-[#0C4AFF] border-[#CDD1D4] h-[48px] px-[16px] rounded-tr-lg rounded-br-lg text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                        type="url"
                        placeholder="Amount available"
                        value={formik.values.amountAvailable}
                        onChange={formik.handleChange("amountAvailable")}
                        onBlur={formik.handleBlur("amountAvailable")}
                      />
                    </div>
                    <h5 className=" text-gray-500 text-sm font-normal  leading-tight">Minimum amount required is 10%</h5>
                  </label>
                </form>
              )}
              <div className=" w-full flex gap-[16px] flex-col justify-center items-center">
                {page !== 4 && (
                  <Link
                    onClick={() => {
                      // setPage(page + 1);
                      moveNext();
                      window.scrollTo(0, 0);
                    }}
                    className="w-full"
                    to=""
                  >
                    <button
                      disabled={page === 1 ? !isvalid1 : page === 2 ? !isvalid2 : page === 3 ? !isvalid3 : page === 4 && !isvalid4}
                      className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full "
                    >
                      {isLoading ? "Loading..." : " Next"}
                    </button>
                  </Link>
                )}
                {page === 4 && (
                  <div
                    onClick={() => {
                      moveNext();
                    }}
                    className="w-full"
                    to=""
                  >
                    <div className="leading-[20px] cursor-pointer border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full ">
                      {isLoading ? "Loading..." : " Submit Application "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-[280px] p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Updated Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Thank you for submitting your documents. Your application is being reviewed, and we will contact you shortly.</p>
                    </div>
                  </div>
                  <Link className="w-full" to="/auth/login">
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Ok</div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default SignUp;
