import { useRef } from "react";
import ContactUs from "../LandingPage/ContactUs";
import Footer from "../LandingPage/Footer";
import Header from "../LandingPage/Header";

function TermsOfUse() {
  const contact = useRef(null);
  const faq = useRef(null);
  const home = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header scrollToSection={scrollToSection} contact={contact} />
      <div className="flex justify-center flex-col pb-[96px] h-fit 100:mt-[180px] md:mt-[150px] items-center  w-full">
        <section className="w-full bg-[#063CCD]  py-[96px] h-fit flex justify-center items-center ">
          <div className=" flex flex-col justify-center items-center gap-[24px] 100:w-[90%] md:w-[650px]">
            <h2 className=" text-center text-white text-5xl font-semibold leading-10">Terms of Use</h2>
            <p className=" text-center text-sky-200 text-xl font-normal  leading-loose">
              Welcome to ijefund.com, a service provided by Greenquarters Technologies Ltd. By accessing or using our platform, you agree to comply with and be bound by these Terms of Use.
            </p>
          </div>
        </section>
        <section className="py-[96px] 100:w-[85%] flex-col  md:w-[720px] h-fit flex justify-center items-center">
          <ol className="w-full list-decimal flex-col justify-start items-start gap-[48px] inline-flex">
            <li className="self-stretch text-neutral-700 text-3xl font-semibold ">
              Acceptance of Terms
              <p className="self-stretch text-gray-500 text-lg font-normal  mt-[24px]">
                By accessing and using ijefund.com ("Platform"), you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any part of these terms, please do not use the Platform.
              </p>
            </li>

            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Description of Service
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                ijefund.com is a co-financing platform that allows prospective African students to fund their postgraduate studies in North America and Europe through a combination of donations from family and friends and loans provided by
                Greenquarters Technologies Ltd. Additionally, the Platform enables lenders to provide funding to students and earn returns on their capital.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Eligibility
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                To use our Platform, you must be at least 18 years old and legally capable of entering into binding contracts. By using the Platform, you represent and warrant that you meet these requirements.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              User Accounts
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                You must create an account to use certain features of the Platform. You agree to provide accurate and complete information and to keep this information updated. You are responsible for maintaining the confidentiality of your account
                credentials and for all activities that occur under your account.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Donations and Loans
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                a. Donations: Users can make donations to support students. These donations are refundable to donors in the event that the students cannot, for any reason, complete relocation to their country of destination.
                <br />
                <br />
                b. Loans: Lenders can provide funding to students through structured loan agreements. Greenquarters Technologies Ltd. facilitates the lending process and ensures repayment structures are in place.{" "}
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              User Conduct
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                You agree not to:
                <br />
                <br />
                Use the Platform for any unlawful purpose.
                <br />
                <br />
                Misrepresent your identity or affiliation with any person or entity.
                <br />
                <br />
                Interfere with or disrupt the Platform or servers or networks connected to the Platform.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Intellectual Property
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                All content, features, and functionality on the Platform, including text, graphics, logos, and software, are the property of Greenquarters Technologies Ltd.. or its licensors and are protected by copyright, trademark, and other
                intellectual property laws.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Disclaimer of Warranties
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                The Platform is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.{" "}
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Limitation of Liability
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                In no event shall Greenquarters Technologies Ltd. be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Platform.{" "}
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Indemnification
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                You agree to indemnify, defend, and hold harmless Greenquarters Technologies Ltd. and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way
                connected with your use of the Platform.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Governing Law
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                These Terms of Use are governed by and construed in accordance with the laws of the Federal Republic of Nigeria without regard to its conflict of law principles.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Changes to Terms
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                Greenquarters Technologies Ltd. reserves the right to modify these Terms of Use at any time. Any changes will be effective immediately upon posting on the Platform. Your continued use of the Platform after the posting of changes
                constitutes your acceptance of such changes.
              </p>
            </li>
            <li className="self-stretch text-neutral-700 text-3xl font-semibold  ">
              Contact Us
              <p className="self-stretch text-gray-500 text-lg font-normal mt-[24px] ">
                If you have any questions about these Terms of Use, please contact us at{" "}
                <a className=" text-blue-700 font-medium hover:underline " href="mailto:hello@ijefund.com">
                  hello@ijefund.com
                </a>
                .
              </p>
            </li>
          </ol>
        </section>
      </div>
      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default TermsOfUse;
